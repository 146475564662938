import React,{ Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import RelateTypeFormContent from './RelateTypeFormContent';
import TextAndDateFormContent from './TextAndDateFormContent'
import SingleAndMultiFormContent from './SingleAndMultiFormContent';

class fieldForm extends Component{

    getValues = () => {
        const form = this.props.form;
        const values = form.getFieldsValue();
        return values ;
    }

   render(){
       let {selectType} =this.props;
       return (
           <div>
               <h3>字段详细信息：</h3>
               <Form layout={'horizontal'} className={'field-form-wrap'}>
                   {selectType['type']=="Related"||selectType['type']=="Detail"?<RelateTypeFormContent {...this.props} />:
                       selectType['type']=="Date"||selectType['type']=="Text"||selectType['type']=="Textarea"||selectType['type']=="Bool"||selectType['type']=="Integer"||selectType['type']=="Real"||selectType['type']=="AutoNum"?<TextAndDateFormContent {...this.props} />:
                           selectType['type']=="SingleSelect"||selectType['type']=="MultiSelect"?<SingleAndMultiFormContent {...this.props} />:<div>暂不支持</div>}
               </Form>
           </div>
       )
   }

}

let FieldForm = Form.create({ name: 'form_in_modal' })(fieldForm);
export default FieldForm;