import React from 'react';
import { Result } from 'antd';

class Success extends React.Component {
    render() {
        const {root} =this.props;
        return (
            <Result
                status="success"
                title="模版创建成功"
                subTitle=""
                extra={[
                    <a href={`${root}/sign/index?tenantId=${window.sessionStorage.getItem('tenantId')}&token=${window.sessionStorage.getItem('token')}`} target="_parent">回到模版列表</a>
                ]}
            />
        );
    }
}

export default Success;
