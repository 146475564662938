import {observable, action} from 'mobx';
import getJSON from '../../api/url.js';

class AddOrEditTplStore {
    @observable objects;
    @observable fieldItems;
    @observable targetKeys;
    @observable disabledTransfer;

    @observable templateUrl;
    @observable fileloading;
    @observable type;
    @observable detailTpl;

    constructor(){
        this.setInitState()
    }
    @action setInitState=()=>{
        this.objects=[];
        this.fieldItems=[];
        this.targetKeys=[];
        this.disabledTransfer=true;

        this.templateUrl='';
        this.fileloading=false;
        this.type='create';
        this.detailTpl=null;
    }
    @action getObject= ()=>{
        let _this=this;
        return getJSON({
            urlKey:'gzyObjectList',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                // customFlg: 0
            },
        }).then((resp)=>{
            _this.objects=resp.data;
            return resp
        },(resp)=>{
            return resp
        })
    };

    @action getItems=(objectId)=>{
        if(!objectId){
            return
        };
        this.disabledTransfer=true;
        let _this=this;
        return getJSON({
            urlKey:'getAllItems',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                objectId: objectId
            },
        }).then((resp)=>{
            _this.fieldItems=resp.data;
            _this.disabledTransfer=false;
            if(_this.type=='edit'){
                _this.setTargetKeys(_this.detailTpl)
            }
            return resp
        },(resp)=>{
            return resp
        })
    };

    @action getOrNewTplContentData=(method,data)=>{
        return getJSON({
            urlKey:'addContractTemplate',
            method:method,
            data
        })
    }

    @action handleTargetChange=(targetKeys, direction, moveKeys)=>{
        let _this=this;
        this.targetKeys=targetKeys;
        this.targetArr=[];
        targetKeys.forEach((il,i)=>{
            _this.fieldItems.forEach((el,e)=>{
                if(il==el.id){
                    _this.targetArr.push({
                        id:el.id,
                        tenantId:el.tenantId,
                        objectId:el.objectId,
                        name:el.name,
                        apiKey:el.apiKey,
                        dbColumn:el.dbColumn
                    })
                }
            })
        })
    }

    @action changeObject=(objectId)=>{
        this.fieldItems=[];
        this.targetKeys=[];
        this.getItems(objectId)
    }

    @action changeTplName=(e)=>{
        if(!this.detailTpl){
            this.detailTpl={}
        }
        this.detailTpl.templateName=e.target.value;
    };

    @action setTplUrl=(url)=>{
        this.templateUrl=url;
        this.setFileLoading(false)
    }

    @action setFileLoading=(isloading)=>{
        this.fileloading=isloading
    }

    @action setOperateType=(obj)=>{
        this.type=obj.type;
        this.getObject();
        if(obj.id){
            this.detailContractTemplate(obj.id);
        }
    }

    @action detailContractTemplate=(id)=>{
        let _this=this;
        return getJSON({
            urlKey:'detailContractTemplate',
            method:'get',
            encodeData:{id,tenantId: window.sessionStorage.getItem('tenantId')}
        }).then((res)=>{
                _this.detailTpl=res.data;
                _this.templateUrl=res.data.templateUrl;
                if(_this.type=='edit'){
                    _this.changeObject(res.data.relateObjectId)
                }else{
                    _this.changeObject(res.data.objectId)
                }

        },(res)=>{
            console.log(res)
        })
    };

    @action setTargetKeys=(detail)=>{
        let arr=detail.items.map((il,i)=>{
            return il.id
        });
        this.handleTargetChange(arr);
    };

    @action editContractTemplate=(data)=>{
        let _this=this;
        return getJSON({
            urlKey:'updateContractTemplate',
            method:'post',
            // encodeData:{id:this.detailTpl.id},
            data
        })
    }
}

const addOrEditTplStore = new AddOrEditTplStore();
export default addOrEditTplStore;
export {addOrEditTplStore}