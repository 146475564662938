import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import Menu from 'antd/es/menu';
import {Link ,withRouter} from 'react-router-dom';

const SubMenu = Menu.SubMenu;

@withRouter
class LeftNav extends React.Component{
    state={
        selectNav:"",
        openKeys:[]
    };
    //根据location变化获取左导航默认打开和选中的菜单项
    getSelectNav = () => {
        let { routes ,setHeaderText}=this.props,
            { pathname }=this.props.location,
            selectNav="",
            selectText='',
            openKeys=[];
        routes.forEach((il,i)=>{
            if(il.path===pathname){
                selectNav=il.path;
                selectText=il.text;
                openKeys[0]=il.path;
            }
            if(!selectNav&&il.children&&il.children.length>0){
                il.children.forEach((cl,c)=>{
                    if(cl.isNav&&cl.path===pathname){
                        selectNav=cl.path;
                        openKeys[0]=cl.pPath;
                        selectText=il.text;
                    }else if(!cl.isNav&&cl.path===pathname){
                        selectNav=cl.key;
                        openKeys[0]=cl.pPath;
                        selectText=il.text;
                    }
                })
            }
        });
        if(setHeaderText){
            setHeaderText(selectText)
        }
        return {selectNav,openKeys};
    };

    // OpenChange=({ item, key, keyPath, domEvent })=>{
    //     console.log(item, key, keyPath, domEvent);
    //     this.setState({
    //         selectNav:key,
    //         openKeys:[keyPath[1]]
    //     })
    //     let { setHeaderText}=this.props;
    //     debugger;
    //     if(setHeaderText){
    //         setHeaderText(item.props.text)
    //     }
    // }

    render=()=>{
        let { routes }=this.props;
        let {selectNav,openKeys}=this.getSelectNav();
        // debugger;
        return (
            <Menu
                theme="light"
                mode="inline"
                selectedKeys={[selectNav]}
                defaultOpenKeys={openKeys}
                // onOpenChange={this.OpenChange}
                // onClick={this.OpenChange}
                 >
                {
                    routes.map((il,i)=>{
                        if(il.children.length>0){
                            return (
                                <SubMenu key={il.key} title={<span><LegacyIcon type={il.icon} /><span>{il.text}</span></span>}>
                                    {(il.children).map((cl,c) => {
                                        if(cl.isNav){
                                            return (
                                                <Menu.Item key={cl.path} ppath={cl.pPath} name={cl.name} text={cl.text}>
                                                    <Link to={cl.path} >
                                                        {/*<Icon type="user" />*/}
                                                        <span className="nav-text">{cl.text}</span>
                                                    </Link>
                                                </Menu.Item>
                                            )
                                        }
                                        return null;
                                    })}
                                </SubMenu>
                            );
                        }else{
                            return (
                                <Menu.Item key={il.key} name={il.id} ppath={il.pPath} text={il.text}>
                                    <Link to={il.key} >
                                        {il.pPath?'':<LegacyIcon type={il.icon}/>}
                                        <span className="nav-text">{il.text}</span>
                                    </Link>
                                </Menu.Item>
                            );
                        }

                    })
                }
            </Menu>
        );
    }
}

export default LeftNav