import AdminIndex from "../admins/index/Index";
import UserIndex from "../user/index/Index";
import Templates from "../admins/sign/templates/Templates";
import AddOrEditTemplate from "../admins/sign/templates/AddOrEditTemplate";
import TemplateWrap from "../admins/sign/templates/TemplateWrap";
import Index from "../admins/object/index";
import Stamps from "../admins/sign/stamp/index";
import StampDetail from "../admins/sign/stamp/StampDetail";
import StampInfo from "../admins/sign/stamp/StampInfo";
import AddOrEditStamp from '../admins/sign/stamp/AddOrEditStamp';
import SignFiles from '../admins/sign/file/SignFiles';
import SignFilesWrap from '../admins/sign/file/SignFilesWrap';
import AccountTabWrap from '../admins/sign/account/accountTabWrap';
import AccountBill from '../admins/sign/account/accountBill';
import AccountBillDetail from '../admins/sign/account/accountBillDetail';

import RoleManage from "../admins/userPemission/roleManage/index";
import UserManage from "../admins/userPemission/userManage/index";
import DepartManage from "../admins/userPemission/departManage/index";
import loadable from "./loadable";

const routes = [
    {
        path: "/admins/adminindex",
        pPath: "",
        id:'index',
        icon:'bank',
        key: "/admins/adminindex",
        isNav: true,
        text: "后台首页",
        children: [],
        component: AdminIndex,
    },
    {
        path: "/admins/adminobject",
        pPath: "",
        icon:'control',
        key: "/admins/adminobject",
        isNav: true,
        text: "标准业务对象",
        id:'object',
        component: Index,
        children: []
    },
    {
        path: "/admins/adminsign",
        pPath: "",
        icon:'audit',
        key: "/admins/adminsign",
        isNav: true,
        text: "电子签约管理",
        id:'sign',
        component: AdminIndex,
        children: [
            {
                path: "/admins/sign/index",
                pPath: "/admins/adminsign",
                key:"/admins/sign/index",
                id:1,
                name:'index',
                component: Templates,
                isNav: true,
                text: "模版"
            },
            {
                path: "/admins/sign/addTpl",
                pPath: "/admins/adminsign",
                key:"/admins/sign/index",
                id:2,
                name:'addTpl',
                component: AddOrEditTemplate,
                isNav: false,
                text: "新建模版"
            },
            {
                path: "/admins/sign/fadadaTpl",
                pPath: "/admins/adminsign",
                key:"/admins/sign/index",
                id:3,
                name:'fadadaTpl',
                component: TemplateWrap,
                isNav: false,
                text: "新建模版第二部"
            },
            {
                path: "/admins/sign/stamp",
                pPath: "/admins/adminsign",
                key:"/admins/sign/stamp",
                id:4,
                name:'stamp',
                component: Stamps,
                isNav: true,
                text: "印章"
            },
            {
                path: "/admins/sign/stampdetail",
                pPath: "/admins/adminsign",
                key:"/admins/sign/stamp",
                id:5,
                name:'stampdetail',
                component: StampDetail,
                isNav: false,
                text: "用印明细"
            },
            {
                path: "/admins/sign/stampinfo",
                pPath: "/admins/adminsign",
                key:"/admins/sign/stamp",
                id:6,
                name:'stampinfo',
                component: StampInfo,
                isNav: false,
                text: "印章详情"
            },
            {
                path: "/admins/sign/addOrEditStamp",
                pPath: "/admins/adminsign",
                key:"/admins/sign/addOrEditStamp",
                id:7,
                name:'addOrEditStamp',
                component: AddOrEditStamp,
                isNav: false,
                text: "新增印章"
            },
            {
                path: "/admins/sign/signFiles",
                pPath: "/admins/adminsign",
                key:"/admins/sign/signFiles",
                id:8,
                name:'signFiles',
                component: SignFiles,
                isNav: true,
                text: "签署文件"
            },
            {
                path: "/admins/sign/SignFilesWrap",
                pPath: "/admins/adminsign",
                key:"/admins/sign/index",
                id:9,
                name:'SignFilesWrap',
                component: SignFilesWrap,
                isNav: false,
                text: "电子合同签署"
            },
            {
                path: "/admins/sign/account",
                pPath: "/admins/adminsign",
                key:"/admins/sign/account",
                id:10,
                name:'account',
                component: AccountTabWrap,
                isNav: true,
                text: "账户"
            },
            {
                path: "/admins/sign/bill",
                pPath: "/admins/adminsign",
                key:"/admins/sign/account",
                id:11,
                name:'AccountBill',
                component: AccountBill,
                isNav: false,
                text: "我的账单"
            },
            {
                path: "/admins/sign/billDetail",
                pPath: "/admins/adminsign",
                key:"/admins/sign/account",
                id:12,
                name:'billDetail',
                component: AccountBillDetail,
                isNav: false,
                text: "消费记录详情"
            },
        ]
    },
    // {
    //     path: "/admins/adminuserPemission",
    //     pPath: "",
    //     icon:'apartment',
    //     key: "/admins/adminuserPemission",
    //     isNav: true,
    //     text: "用户与权限",
    //     id:'userPermission',
    //     component: AdminIndex,
    //     children: [
    //         {
    //             path: "/admins/userPemission/role",
    //             pPath: "/admins/adminuserPemission",
    //             key:"/admins/userPemission/role",
    //             id:1,
    //             name:'role',
    //             component: RoleManage,
    //             isNav: true,
    //             text: "角色"
    //         },
    //         {
    //             path: "/admins/userPemission/user",
    //             pPath: "/admins/adminuserPemission",
    //             key:"/admins/userPemission/user",
    //             id:2,
    //             name:'user',
    //             component: UserManage,
    //             isNav: true,
    //             text: "用户"
    //         },
    //         {
    //             path: "/admins/userPemission/depart",
    //             pPath: "/admins/adminuserPemission",
    //             key:"/admins/userPemission/depart",
    //             id:3,
    //             name:'depart',
    //             component: DepartManage,
    //             isNav: true,
    //             text: "部门"
    //         },
    //     ]
    // }
];
const userRoutes =[
    {
        path: "/userindex",
        pPath: "",
        id:'index',
        key: "/userindex",
        isNav: true,
        text: "user首页",
        children: [],
        component: UserIndex,
    },
    {
        path: "/user/userobject",
        pPath: "",
        key: "/user/userobject",
        isNav: true,
        text: "user的某个模块",
        id:'object',
        component: UserIndex,
        children: [
            // {
            //     path: "/user/object/text",
            //     pPath: "/user/object",
            //     id:1,
            //     name:'test',
            //     component: Test,
            //     isNav: true,
            //     text: "合同"
            // }
        ]
    }
];

const gzyRoutes=[
    {
    path: "/modules/adminsign",
    root:"/modules",
    pPath: "",
    icon:'audit',
    key: "/modules/adminsign",
    isNav: true,
    text: "电子签约管理",
    id:'sign',
    component: AdminIndex,
    children: [
        {
            path: "/modules/sign/index",
            root:"/modules",
            pPath: "/modules/adminsign",
            key:"/modules/sign/index",
            id:1,
            name:'index',
            component: Templates,
            isNav: true,
            text: "模版"
        },
        {
            path: "/modules/sign/addTpl",
            root:"/modules",
            pPath: "/modules/adminsign",
            key:"/modules/sign/index",
            id:2,
            name:'addTpl',
            component: AddOrEditTemplate,
            isNav: false,
            text: "新建模版"
        },
        {
            path: "/modules/sign/fadadaTpl",
            root:"/modules",
            pPath: "/modules/adminsign",
            key:"/modules/sign/index",
            id:3,
            name:'fadadaTpl',
            component: TemplateWrap,
            isNav: false,
            text: "新建模版第二部"
        },
        {
            path: "/modules/sign/stamp",
            pPath: "/modules/adminsign",
            key:"/modules/sign/stamp",
            id:4,
            name:'stamp',
            component: Stamps,
            isNav: true,
            text: "印章"
        },
        {
            path: "/modules/sign/stampdetail",
            pPath: "/modules/adminsign",
            key:"/modules/sign/stamp",
            id:5,
            name:'stampdetail',
            component: StampDetail,
            isNav: false,
            text: "用印明细"
        },
        {
            path: "/modules/sign/stampinfo",
            pPath: "/modules/adminsign",
            key:"/modules/sign/stamp",
            id:6,
            name:'stampinfo',
            component: StampInfo,
            isNav: false,
            text: "印章详情"
        },
        {
            path: "/modules/sign/addOrEditStamp",
            pPath: "/modules/adminsign",
            key:"/modules/sign/addOrEditStamp",
            id:7,
            name:'addOrEditStamp',
            component: AddOrEditStamp,
            isNav: false,
            text: "新增印章"
        },
        {
            path: "/modules/sign/signFiles",
            pPath: "/modules/adminsign",
            key:"/modules/sign/signFiles",
            id:8,
            name:'signFiles',
            component: SignFiles,
            isNav: true,
            text: "签署文件"
        },
        {
            path: "/modules/sign/SignFilesWrap",
            pPath: "/modules/adminsign",
            key:"/modules/sign/index",
            id:9,
            name:'SignFilesWrap',
            component: SignFilesWrap,
            isNav: false,
            text: "电子合同签署"
        },
        {
            path: "/modules/sign/account",
            pPath: "/modules/adminsign",
            key:"/modules/sign/account",
            id:10,
            name:'account',
            component: AccountTabWrap,
            isNav: true,
            text: "账户"
        },
        {
            path: "/modules/sign/bill",
            pPath: "/modules/adminsign",
            key:"/modules/sign/account",
            id:11,
            name:'AccountBill',
            component: AccountBill,
            isNav: false,
            text: "我的账单"
        },
        {
            path: "/modules/sign/billDetail",
            pPath: "/modules/adminsign",
            key:"/modules/sign/account",
            id:12,
            name:'billDetail',
            component: AccountBillDetail,
            isNav: false,
            text: "消费记录详情"
        },
    ]
},
    {
    path: "/modules/adminobject",
    root:"/modules",
    pPath: "",
    icon:'audit',
    key: "/modules/adminobject",
    isNav: true,
    text: "标准实体对象",
    id:'object',
    component: Index,
    children: []
}];
const BIT=16;
const licenses={sign: [10, "电子签约"]};

function licensePer(per) {
    return true;
    let licenses = window.sessionStorage.getItem('licenses');
    if (!per || !licenses) return false;
    let code = per[0];
    let index = parseInt(code / BIT);
    let begin = licenses.length - (index + 1) * BIT;
    if(0 > begin) return false;
    let permission = parseInt(licenses.substring(begin, begin + BIT), 2);

    let result = (permission & (1 << (code % BIT))) > 0;
    return result
}

export default {routes,userRoutes,gzyRoutes,licenses,licensePer}


