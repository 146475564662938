import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Button, Tag, message, Select, Row, Col, Input, Drawer, Modal ,Image} from "antd";
import {observer, inject} from 'mobx-react';
import moment from 'moment';
import {Utils} from "../../../utils";
import GzyDateRangePicker from '../../../../components/gzyDateRangePicker';
import './signFile.less';
const Option = Select.Option;

@observer
class FileList extends Component{
    state={
        showDrawer:false,
    };
    store=this.props.store;

    goMsg=(item)=>{
        const {root}=this.props;

    };

    changeCreate=(field,value)=>{
        this.store.setDate('sendTime',field,value)
    };

    changeUpdate=(field,value)=>{
        this.store.setDate('updateAt',field,value)
    };

    showDetail=(item)=>{
        let _this=this;
        let sign_state=item.status;
        if(sign_state===4||sign_state===3||sign_state===2){
            let str=sign_state===4?'该文件已被拒签，不能被查看！':
                sign_state===3?"该文件已过期，不能被查看！":"该文件已被撤回，不能被查看！";
            Modal.info({
                title: '提示',
                content: (
                    <p>{str}</p>
                ),
                onOk() {},
            });
        } else{
            this.setState({
                showDrawer:true
            });
            this.store.getSignDetail(item.dataId).then((resp)=>{
                if(resp.code!=0){
                    message.error(resp.msg||"签署文件详情信息获取失败！");
                    return;
                }
                if(resp.data.contractStatus==5){
                    _this.doSign(true)
                }
            })
        }
    };

    onClose=()=>{
        this.setState({
            showDrawer:false
        });
        this.store.getFileList();
    };

    getColumn=()=> {
        let _this=this;
        let columns=[
            {
                title:'文件序号',
                dataIndex:'docCode',
                width:'10%',
            },
            {
                title:'单据编号',
                dataIndex:'dataCode',
                width:'15%',
                render:(text, record, index)=>{
                    return text
                }
            },
            {
                title:'文件名称',
                dataIndex:'docTitle',
                width:'15%',
            },
            {
                title:'发起人',
                width:'7%',
                dataIndex:'createByName',
            },
            {
                title:'签署主体',
                dataIndex:'signerList',
                render:(text, record, index)=>{
                    var nameList = text.map(function (item, index) {
                        return (<div><span>-{item}</span><br /></div>);
                    });
                    return (<div >{nameList}</div>)
                },
            },
            {
                title:'发起时间',
                dataIndex:'sendTime',
                width:'12%',
                render:time => time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-',
            },
            {
                title:'更新时间',
                dataIndex:'updatedAt',
                width:'12%',
                render:time => time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-',
            },
            {
                title:'签署状态',
                dataIndex:'status',
                width:'8%',
                render:(text, record, index)=>{
                    let obj=Utils.getSignText(text);
                    return (<Tag color={obj.color} onClick={this.showDetail.bind(this,record)}>{obj.text}</Tag>)
                },
            },
            // {
            //     title:'操作',
            //     dataIndex:'operate',
            //     render:(text, record, index)=>{
            //         return (
            //             <Icon type={'bell'} onClick={this.goMsg.bind(_this,record)} className={'text-blue'}/>
            //         )
            //     }
            // },
        ];
        return columns
    };

    searchHandle=(type,val)=>{
        this.props.parentSearchHandle(type,val);
        // this.store[type]=val;
    };
    searchBlock= ()=>{
        let {createByName='',dataCode='',docTitle='',docCode='',status='',signerName='',
            clearSearchList,searchHandle,sendTimeBgn,sendTimeEnd,updateAtBgn,updateAtEnd} =this.store;
        return (<div>
                <Row gutter={2} style={{marginBottom:10}} justify={'space-between'}>
                    <Col xs={4}>
                        <Input style={{width:'80%'}} placeholder={'请输入文件序号'} value={docCode||undefined} onChange={(val)=>{this.searchHandle('docCode',val.target.value)}} />
                    </Col>
                    <Col xs={4}>
                        <Input style={{width:'80%'}} placeholder={'请输入单据编号'} value={dataCode||undefined} onChange={(val)=>{this.searchHandle('dataCode',val.target.value)}} />
                    </Col>
                    <Col xs={6}>
                        <Input style={{width:'80%'}} placeholder={'请输入发起人名'} value={createByName||undefined} onChange={(val)=>{this.searchHandle('createByName',val.target.value)}} />
                    </Col>
                    <Col xs={6}>
                        <Input style={{width:'80%'}} placeholder={'请输入签署人名'} value={signerName||undefined} onChange={(val)=>{this.searchHandle('signerName',val.target.value)}} />
                    </Col>
                    <Col xs={3}/>
                </Row>
                <Row gutter={2} style={{marginBottom:10}} justify={'space-between'}>
                    <Col xs={4}>
                        <Select style={{minWidth:'80%'}} placeholder={'请选择签署状态'} value={status||undefined} onChange={(val)=>{this.searchHandle('status',val)}}>
                            {Utils.SIGN_STATE.map((il,i)=>{
                                return <Option value={il.value} key={il.value}>{il.text}</Option>
                            })}
                        </Select>
                    </Col>
                    <Col xs={4}>
                        <Input style={{width:'80%'}} placeholder={'请输入文件名称'} value={docTitle||undefined} onChange={(val)=>{this.searchHandle('docTitle',val.target.value)}} />
                    </Col>
                    <Col xs={6}>
                        <GzyDateRangePicker Start={sendTimeBgn} placeholder={['发起开始时间','发起结束时间']} End={sendTimeEnd} showTime={true} rangeChange={this.changeCreate}/>
                    </Col>
                    <Col xs={6}>
                        <GzyDateRangePicker Start={updateAtBgn} placeholder={['更新开始时间','更新结束时间']} End={updateAtEnd} isclearTime showTime={true} rangeChange={this.changeUpdate}/>
                    </Col>
                    <Col xs={3} className="text-right" >
                        <Button type="default" size="small" onClick={clearSearchList}>重置</Button>
                        <Button type="primary" size="small" onClick={()=>{searchHandle(null)}} style={{marginLeft:20}}>查询</Button>
                    </Col>
                </Row>
            </div>
        )
    };
    refuseSign=()=>{
        this.store.refuseSign().then((res)=>{
            this.setState({
                showDrawer:false,
            })
            this.store.getFileList();
            message.success('拒签成功！')
        },(res)=>{
            message.success(res.msg)
        })
    };
    recallSign=()=>{
        this.store.recallSign().then((res)=>{
            this.setState({
                showDrawer:false,
            })
            this.store.getFileList();
            message.success('撤回成功！')
        },(res)=>{
            message.success(res.msg)
        })
    };
    doSign=(isStoreUrl)=>{
        let _this=this;
        let {root,history} =this.props;
        if(isStoreUrl){
            this.store.doSign().then((res)=>{
                _this.store.doSignUrl=res.data.signUrl;
            },(res)=>{
                message.success(res.msg)
            })
        }else{
            history.push({pathname: `${(root?`${root}`:'')}/sign/SignFilesWrap`,state: {fadada:_this.store.doSignUrl}})
        }

    };
    operateConfirm=(type)=>{
        let _this=this;
        this.Modal=Modal.confirm({
            title:'确定进行该操作？',
            centered:true,
            // className:'buy-modal',
            maskClosable:false,
            // icon:'',
            okText:'确定',
            cancelText:'取消',
            // content:'',
            okButtonProps:{
                type:'primary',
                size:'small',

            },
            cancelButtonProps:{
                type:'primary',
                size:'small',
                ghost:true,
            },
            onCancel:()=>{
                _this.closeModal();
            },
            onOk:()=>{
                _this[type]();
                _this.closeModal();
            }

        });
    };

    closeModal=()=>{
        this.Modal.destroy();
    };
    getSignerListHtml(){
        if (!this.store.detailData.signerList) {
            return (<div></div>)
        }
        return this.store.detailData.signerList.map((signerDetail) => {
            return (
                <div>
                    <h3 className={'border-tit'}>{signerDetail.signerSpell === "A" ? "出租方" : signerDetail.signerSpell === "B" ? "承租方" : "第三方"}</h3>
                    <div className={'file-detail-draw-info-p-wrap'}>
                        <p className="text-gray">签署主体：{signerDetail.accountName}</p>
                        <p className="text-gray">签署人账号：{signerDetail.accountMobile}</p>
                        <p className="text-gray">签署状态：{
                            signerDetail.signStatus === 0 ? "未读未签" :
                                signerDetail.signStatus == 1 ? "已读未签" :
                                    signerDetail.signStatus == 2 ? "已撤回" :
                                        signerDetail.signStatus == 3 ? "已过期" :
                                            signerDetail.signStatus == 4 ? "已拒签" :
                                                signerDetail.signStatus == 6 ? "已签署" : ""
                        }</p>
                        <p className="text-gray">时间：{signerDetail.signTime&&moment(signerDetail.signTime).format('YYYY-MM-DD HH:mm:ss')}</p>
                        <p className="text-gray">签章方式：{
                            signerDetail.mobileSignType === 0 ? "全部" : signerDetail.mobileSignType == 1 ? "标准" : signerDetail.mobileSignType == 2 ? "手写" : ""
                        }</p>
                        <div>
                            <Image width={50} src={signerDetail.idCardHeadPath && signerDetail.idCardHeadPath}/>
                            <Image width={50} src={signerDetail.idCardBackPath && signerDetail.idCardBackPath} />
                            <Image width={50} src={signerDetail.facePhotoPath && signerDetail.facePhotoPath} />
                        </div>
                    </div>
                </div>
            )
        });
    };

    render(){
        let {fileList,loading,current,size,total,searchHandle,detailData} =this.store;
        return (
            <div>
                {this.searchBlock()}
                <Table className={'m-b-10'} loading={loading}
                    // scroll={{ x: 1500 }}
                       columns={this.getColumn()} rowKey={'id'} dataSource={fileList} size={'small'}
                       total={total}
                       onChange={(pagination, filters, sorter)=>{
                           searchHandle(pagination);
                       }}
                       pagination={{
                           showSizeChanger:true,
                           showQuickJumper:true,
                           defaultCurrent:1,
                           current:current,
                           pageSize:size,
                           total:total,
                       }} />
                <Drawer
                    title={(<div className={'flex space-between'}><span>签署文件详情</span><LegacyIcon type={'close'} onClick={this.onClose}/></div>)}
                    placement="right"
                    width={'86%'}
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.showDrawer}
                >
                    <div style={{'display':'flex'}}>
                        <iframe src={detailData.viewUrl||''} className={'frame'} />
                        <div className={'file-detail-draw-info'}>
                            { this.getSignerListHtml() }
                            <span className="create-by">
                                <span>发起人：{detailData.createdByName}</span>
                                <span>发起时间：{moment(detailData.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                                <span>过期时间：{moment(detailData.signExpireTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                            </span>
                            <div className="file-detail-draw-info-p-wrap" >
                                 <img className="img-qr" src={detailData.qrCodeUrl} />
                            </div>
                            <div className="drawer-layer-footer">

                                {detailData.contractStatus===5?<Button type="primary" size={'small'}  className="bg-green" onClick={()=>this.operateConfirm('doSign')}>签署</Button>:null}
                                {detailData.contractStatus===5?<Button type="danger" size={'small'} className="bg-red" onClick={()=>this.operateConfirm('refuseSign')}>拒签</Button>:null}
                                {detailData.signedNum===0?<Button size="small" className="" onClick={()=>this.operateConfirm('recallSign')}>撤回</Button>:null}
                                <a className="" href={detailData.downloadUrl} download="">
                                    <Button size={'small'} type={'default'}>下载</Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }

    componentDidMount(){

    }
    componentWillUnmount(){

    }
}

export default FileList
