import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Tree, Modal } from "antd";
import {inject, observer} from "mobx-react";
import DepartForm from './AddOrEditCompAndDept';
import HeaderContent from '../../../../modules/layouts/headerContent';
import './departManage.less'


const {Content,Sider} = Layout;
const { TreeNode } = Tree;


@inject("departIndexStore")
@observer
class Test extends Component{
    state = {
        visible:false
    };

    store=this.props.departIndexStore;

    // tree列表上右键事件
    onRightClick = e => {
        this.setState({
            isRightClick:true,
            rightClickNodeTreeItem: {
                pageX: e.event.pageX,
                pageY: e.event.pageY,
                id: e.node.props["data-key"],
                categoryName: e.node.props["data-title"]
            }
        });
    };

    addComp=(node)=>{
        console.log("node",node)
    };

    editComp=(node)=>{
        alert(2)
    };


    onSelect = (selectedKeys, e) => {
        // console.log('selected', selectedKeys, info);
        this.store.setSelectedKeys(selectedKeys);
        console.log(e);
    };

    onCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
    };

    componentWillUnmount(){}

    componentWillMount(){
        this.store.getTreeComp();
    }
    getTitle=(node)=>{
        let {selectedKeys,compTree} =this.store;
        let select=selectedKeys[0];
        let ppid=compTree?compTree:{};
        return (
            <div className="title-wrap">
                <span className={'m-r-10 title-content'}>{node.name||node.deptName}</span>
                <span className={'operate-icons'} style={{display:select==node.id?'inline':'none'}} >
                    <LegacyIcon className={'m-r-10'} onClick={this.addComp.bind(this,node)} type={'plus'} />
                    <LegacyIcon className={'m-r-10'} style={{display:ppid.id==node.id?'none':'inline'}} onClick={this.editComp.bind(this,node)} type={'edit'} />
                    <LegacyIcon type={'delete'} style={{display:ppid.id==node.id?'none':'inline'}} />
                </span>
            </div>
        );
    }

    getTreeNodes=(node)=>{
        let titleNode=this.getTitle(node);
        return <TreeNode title={titleNode} key={node.id}>
            {node.childCompanies&&node.childCompanies.length>0?node.childCompanies.map((il,i)=>{
                return this.getTreeNodes(il)
            }):null}
            {node.childDepts&&node.childDepts.length>0?node.childDepts.map((il,i)=>{
                return this.getTreeNodes(il)
            }):null}
        </TreeNode>
    }


    render(){
        let {compTree,selectedKeys,modalTitle,btnLoading} =this.store;

        return (
            <Layout className="right-content-admin-layout" >
                <HeaderContent history={this.props.history} text={this.props.text}/>
                <Content className="right-content">
                    <Layout className={'bg-white'}>
                        <Sider theme={'light'}  className={'sider-wrap'}>
                            {compTree?<Tree
                                style={{width:250}}
                                onSelect={this.onSelect}
                                showLine={true}
                                selectedKeys={selectedKeys}
                                className={'depart-tree'}
                                // showIcon={true}
                                defaultExpandedKeys={[compTree.id]}
                            >
                                    {this.getTreeNodes(compTree)}
                            </Tree>:'正在加载中。。。'}
                            {/*{this.state.isRightClick?this.getNodeTreeRightClickMenu():null}*/}
                        </Sider>
                        <Content>
                            xfgdfgdgf
                        </Content>
                    </Layout>
                </Content>
                <Modal title={modalTitle}
                       visible={this.state.visible}
                       width={500}
                       onOk={this.OkHandler}
                       onCancel={this.hideModal}
                       okText="保存"
                       closeIcon={(<span className={'text-black'}>关闭</span>)}
                       okButtonProps={{ loading:btnLoading }}
                       cancelText="取消">
                    <DepartForm wrappedComponentRef={formRef => {
                        this.formRef = formRef;
                    }} store={this.store} type={'create'}/>
                </Modal>
            </Layout>

        )
    }
}

export default Test
