import React,{ Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Radio } from 'antd';

const formItemLayout={
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

class DepartMentForm extends Component{

    render(){
        let {type} =this.props;
        const {getFieldDecorator} =this.props.form;
        return (
            <div>
                <Form layout={'horizontal'}>
                    <Form.Item label="Radio.Group" {...formItemLayout}>
                        {getFieldDecorator('type')(
                            <Radio.Group>
                                <Radio value="comp">分公司</Radio>
                                <Radio value="dept">部门</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label="名称" {...formItemLayout}>
                        {getFieldDecorator('name')(
                            <Input disabled />
                        )}
                    </Form.Item>
                    <Form.Item label="描述" {...formItemLayout}>
                        {getFieldDecorator('desc')(
                            <Input.Textarea disabled />
                        )}
                    </Form.Item>
                </Form>
            </div>
        )
    }

}

let DepartForm = Form.create({ name: 'form_in_modal' })(DepartMentForm);
export default DepartForm;