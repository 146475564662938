import React,{ Component } from 'react';
import {Layout} from "antd/lib/index";
import HeaderContent from '../../../../modules/layouts/headerContent';
const { Header, Content} = Layout;


export default class Test extends Component{
    state={
        activeTabKey:'field'
    };
    tabsData=()=> {
        let data=[

        ];
        return data
    };

    render(){
        return (
            <Layout className="right-content-admin-layout" >
                <HeaderContent history={this.props.history} text={this.props.text}/>
                <Content className="right-content">

                </Content>
            </Layout>

        )
    }
}
