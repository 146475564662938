import {observable, action} from 'mobx';
import {message} from 'antd';
import getJSON from '../../api/url.js';

class TenantListStore {
    @observable size;
    @observable total;
    @observable current;
    @observable tenantList;
    @observable isLoading;
    @observable company;
    @observable scale;
    @observable rentalModel;
    @observable salesman;
    @observable status;
    @observable expireFlag;
    @observable type;
    @observable statistics ;

    constructor() {
        this.size = 20;
        this.total = 0;
        this.current = 1;
        this.tenantList = [];
        this.isLoading=false;
        this.company='';
        this.scale='';
        this.rentalModel='';
        this.salesman='';
        this.status='';
        this.expireFlag='';
        this.type=0;
        this.statistics=null;
    }
    @action searchHandle=(type,value,pageObject) =>{
        if(type!=='filter'){
            this[type]=value;
            this.current=1;
        }else{
            let that=this;
            Object.keys(value).forEach((key)=>{
                if(value[key]&&(value[key].length!=that[key].length||that[key]!=value[key].join())){
                    that[key]=value[key];
                }
            });
            this.current=pageObject?pageObject.current:1;
            this.size=pageObject?pageObject.pageSize:20;
        };
        this.getTenants();

    };
    @action searchList=(page,pageSize)=>{
        let {company,scale,rentModel,expireFlag,salesman,status} =this;
        let data={};
        if(company){data['company']=company;}
        if(scale){data['scale']=Number(scale);}
        if(rentModel){data['rentalModel']=Number(rentModel);}
        if(expireFlag){data['expireFlag']=Number(expireFlag);}
        if(salesman){data['salesman']=salesman;}
        if(status!==''){data['status']=status;}
        if(pageSize){this.size=pageSize;}
        this.getTenants(page,data)
    };

    @action clearSearchList=(nofresh)=>{
        this.company='';
        this.scale='';
        this.rentalModel='';
        this.expireFlag='';
        this.salesman='';
        this.status='';
        this.current=1;
        this.size=20;
        this.getTenants();
    };
    @action clearState= () => {
        this.tenantList = [];
        this.isLoading=false;
        this.statistics=null;
        this.clearSearchList(true);
    };
    @action getStatistics=()=>{
        let that=this;
        getJSON({
            urlKey: 'statistics',
            method: 'get',
        }).then((res)=>{
            let {data} = res;
            that.statistics=data.statistics
        })
    };
    @action getTenants = (page,data) => {
        let _this = this;
        let {company,scale,rentalModel,expireFlag,salesman,status,type} =this;
        this.isLoading=true;
        this.getStatistics();
        getJSON({
            urlKey: 'tenants',
            method: 'post',
            encodeData: {
                current: this.current,
                size:(this.size?this.size:10)
            },
            data:{
                company:company.toString(),
                scale:scale.toString(),
                rentalModel:rentalModel.toString(),
                expireFlag:expireFlag.toString(),
                salesman:salesman.toString(),
                status:status.toString(),
                registerType:type
            }
        }).then((resp) => {
            let {data} = resp;
            console.log(resp);
            if (null != data) {
                _this.tenantList = data.records;
                _this.total = data.total;
                _this.size = data.size;
                _this.current = data.current;
                _this.isLoading=false;
            }
        })
    };
    @action deleteTenant = (id) => {
        let _this = this;
        return getJSON({
            urlKey: 'deleteTenant',
            method: 'delete',
            urlData:[Number(id)],
        }).then((resp) => {
            if (resp.code === 0) {
                message.success('删除成功！');
                _this.getTenants();
            } else {
                message.error('删除失败！');
            }
        })
    };
    @action setRegisterType = (type) => {
        this.type = type;
    }
}

const tenantListStore = new TenantListStore();//通过new 创建一个homeStore对象实例通过export导出
export default tenantListStore;
export {tenantListStore}