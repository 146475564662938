import {observable, action} from 'mobx';
import axios from 'axios';
import getJSON from '../../api/url.js';
import {message} from "antd";

class AccountStore{

    @observable loading;
    @observable account;
    @observable pkgs;

    @observable activeBusiness;
    @observable outTradeNo;




    constructor(){
        this.setInitState();
    }
    @action setInitState=(isreset)=>{
        this.account={};
        this.pkgs=[];
        this.loading=false;
        this.activeBusiness='ESIGN';
        this.outTradeNo='';
    }

    @action getAccount=(param)=>{
        this.loading=true;
        let _this=this;
        return getJSON({
            urlKey:'account',
            method:'post',
            encodeData:{
                businessType:this.activeBusiness,
                outTradeNo:this.outTradeNo
            },
            data:{}
        }).then((res)=>{

            _this.account=res.data;
            return res
        },(res)=>{
            debugger
            return res;
        })
    };
    @action getPackage=(param)=>{
        let _this=this;

        return getJSON({
            urlKey:'package',
            method:'post',
            encodeData:{
                businessType:this.activeBusiness
            },
            data:{}
        }).then((res)=>{
            _this.pkgs=res.data;
            _this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    };
    @action setUser=(userId,userName)=>{
        this.userId=userId;
        this.userName=userName;
    }

    @action showBuy=(buydata,type)=>{
        return getJSON({
            urlKey:'pkgPurchase',
            method:'post',
            data:{
                "pck":{
                    "id":type=='BALANCE'?buydata.tenantPck.pckId:buydata.id
                },
                "consumePattern":'WX',
                "userId":this.userId,
                "userName":this.userName,
                businessType:this.activeBusiness
            }
        }).then((res)=>{
            this.outTradeNo=res.data.outTradeNo;
            return res;
        },(res)=>{
            message.error(res.msg);
            return res
        })
    }

    @action changeBusinessTab=(activekey)=>{
        this.activeBusiness=activekey;
        this.outTradeNo='';
        this.getAccount();
        this.getPackage();
    }

}

const accountStore = new AccountStore();
export default accountStore;
export {accountStore}