import React,{ Component } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PlusOutlined } from '@ant-design/icons';
import { Layout, Button, Input, Select, Spin, message, Transfer } from "antd";
import {observer, inject} from 'mobx-react';
import UploadOss from '../../../../components/uploadOss/upload';
import HeaderContent from '../../../../modules/layouts/headerContent';
import './templatForm.less'
const { Content} = Layout;

const formItemLayout={
    labelCol: { span: 5 },
    wrapperCol: { span: 14 }
};
const Option=Select.Option;
@inject('addOrEditTplStore')
@observer
class AddTemplate extends Component{
    state = {
        saveLoading:false,
    };
    store=this.props.addOrEditTplStore;

    showLoading = (type)=>{
        if(type==='fileloading'){
            this.store.setFileLoading(true)
        }
    };

    validImgSrc= (rule, value, callback) => {
        let { field } = rule;
        switch (field){
            case 'templateUrl':
                if( !this.store.templateUrl){
                    callback('请上传模版文件')
                }
                return callback();
            default :
                console.log("------->",rule,value,callback);
        }

    };

    validTransfer= (rule, value, callback)=>{
        let {targetKeys} =this.store;
        if(targetKeys.length===0){
            callback('请选择至少一个实体字段！')
        }
        return callback();
    };

    handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    filterOption = (inputValue, option) => option.label.indexOf(inputValue) > -1;

    saveData = ()=>{
        const { form,root } = this.props;
        const {targetArr,templateUrl} =this.store;
        const _this=this;
        form.validateFields({ force: true },(err, values) => {
            if (err) {
                return;
            }

            let postData={
                templateUrl,
                tenantId: window.sessionStorage.getItem('tenantId'),
                templateName:values['templateName'],
                templateDesc:values['templateDesc'],
                objectId:values['objectId'],
                items:targetArr,
                returnUrl:window.location.origin+(root?`${root}`:'')+'/sign/index?success=true'
            };
            _this.setState({
                saveLoading:true
            });
            if(_this.store.type ==='create'){
                _this.store.getOrNewTplContentData('post',postData).then((res)=>{
                    if(res.code===0){
                        message.success(res.msg);
                        _this.setState({
                            saveLoading:false
                        });
                        _this.props.history.push({pathname: `${(root?`${root}`:'')}/sign/fadadaTpl`,state: {fadada:res.data.templateEditUrl}})
                    }
                },(res)=>{
                    _this.setState({
                        saveLoading:false
                    });
                    message.error(res.msg||'服务端错误')
                });
            }else{
                postData.id=_this.store.detailTpl.id;
                _this.store.editContractTemplate(postData).then((res)=>{
                    if(res.code===0){
                        message.success(res.msg);
                        _this.setState({
                            saveLoading:false
                        });
                        _this.props.history.push({pathname: `${(root?`${root}`:'')}/sign/fadadaTpl`,state: {fadada:res.data.templateEditUrl}})
                    }
                },(res)=>{
                    _this.setState({
                        saveLoading:false
                    });
                    message.error(res.msg||'服务端错误')
                });
            }
        });
    };
    getFileName=(url)=>{
        let strarr=url.split('_');
        return strarr[strarr.length-1];
    };

    render(){
        const { saveLoading} =this.state;
        const {form,root}=this.props;
        const {getFieldDecorator} = form;
        const {objects,fieldItems,targetKeys,templateUrl,fileloading,disabledTransfer,detailTpl,type,
            setFileLoading,setTplUrl,handleTargetChange,changeObject,changeTplName} =this.store;
        let templateName=detailTpl?detailTpl.templateName:'';
        if(!templateName&&templateUrl){
            let strarr=templateUrl.split('_');
            templateName=strarr[strarr.length-1].split('.')[0];
        };

        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} text={`${type==='create'?this.props.text:'编辑模版'}`}>
                    <Button className={'m-r-15'} style={{float:'right',top:10}} type={'default'} size='small' icon={<LegacyIcon type={'arrow-left'} />} onClick={()=>{
                        this.props.history.goBack()
                    }}>返回</Button>
                </HeaderContent>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <Form layout={'horizontal'} className={'template-form'}>
                        <h3 className="tpl-border-tit">上传模版文件</h3>
                        <Form.Item {...formItemLayout} label="模版上传">
                            {getFieldDecorator('templateUrl', {
                                rules: [ {
                                    required:true,
                                    validator: this.validImgSrc,
                                }],
                            })(
                                <div style={{textAlign:'left'}}>
                                    {type==='create'?<UploadOss
                                        url='ossSignature'
                                        showLoading={this.showLoading}
                                        type={'fileloading'}
                                        canChoose={!fileloading}
                                        uploadEnd={(data) => {
                                            setTplUrl(data)
                                        }}
                                        upload={(data, file, load) => {
                                            let reg = /\.(?:doc|docx|wps|pdf|xls|xlsx)$/i;
                                            if(!file.name.match(reg)){
                                                message.warn("上传的文件格式不正验，请重新选择!");
                                                setFileLoading(false);
                                                return;
                                            }
                                            data=JSON.parse(data.data);
                                            load(Object.assign({}, data, {file}))
                                        }}
                                    >
                                        <div className={'sim-plus-img'} >
                                            <span>
                                                {
                                                    fileloading?
                                                        <Spin spinning={fileloading} />:
                                                        <Button type="primary" icon={<PlusOutlined />} ghost>{templateUrl?'重新选择模版文件':'选择模版文件'}</Button>
                                                }
                                            </span>
                                            <span className={'m-l-15'}>(当前模板在线编辑功能仅支持 .doc 、.docx 、.wps 、.pdf 、.xls 、.xlsx格式)</span>
                                        </div>
                                    </UploadOss>:null}
                                    { templateUrl ?
                                        <a  href={templateUrl} className={'ellipsis'}>{this.getFileName(templateUrl)}</a>
                                        :''}
                                </div>
                            )}
                        </Form.Item>
                        <h3 className="tpl-border-tit">填写模板信息</h3>
                        <Form.Item label="模版名称" {...formItemLayout}>
                            {getFieldDecorator('templateName', {
                                rules: [{required: true, message: '请输入'}],
                                initialValue:templateName
                            })(
                                <Input placeholder={'请输入'} onChange={changeTplName}/>
                            )}
                        </Form.Item>
                        <Form.Item label="选择实体" {...formItemLayout}>
                            {getFieldDecorator('objectId', {
                                rules: [{required: true, message: '请选择'}],
                                initialValue:detailTpl?detailTpl.relateObjectId:''
                            })(
                                <Select onChange={changeObject} placeholder={'请选择'} disabled={type=='edit'}>
                                    {objects.map((item,i)=>{
                                        return <Option key={item.objectId} value={item.objectId}>{item.name}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="选择实体字段" {...formItemLayout}>
                            {getFieldDecorator('items', {
                                rules: [{required: true, message: '请选择',validator: this.validTransfer,}],
                            })(
                                <Transfer
                                    dataSource={fieldItems}
                                    showSearch
                                    disabled={disabledTransfer}
                                    filterOption={this.filterOption}
                                    targetKeys={targetKeys}
                                    onChange={handleTargetChange}
                                    onSearch={this.handleSearch}
                                    listStyle={()=>{
                                        return {width:'46.5%',height:300}
                                    }}
                                    render={item => {
                                        item.label=item.name;
                                        item.key=item.id;
                                        return item
                                    }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="模版说明" {...formItemLayout}>
                            {getFieldDecorator('templateDesc', {
                                rules: [
                                    {max:200,message:'请输入200字以内的内容'}],
                                initialValue:detailTpl?detailTpl.templateDesc:''
                            })(
                                <Input.TextArea placeholder={'请输入模版说明（200字以内）'} />
                            )}
                        </Form.Item>
                    </Form>
                    <div className={'tpl-footer'}>
                        <Button type={'primary'} size={'large'} loading={saveLoading} onClick={this.saveData} className={'tpl-button'}>下一步</Button>
                    </div>
                </Content>

            </Layout>
        );
    }

    componentWillMount(){
        const {location} =this.props;
        if(location.state&&location.state){
            this.store.setOperateType(location.state)
        }
    }

    componentWillUnmount(){
        this.store.setInitState()
    }
}

let AddOrEditTemplate = Form.create({ name: 'template_form' })(AddTemplate);
export default AddOrEditTemplate;