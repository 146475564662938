import React from 'react';
import { Layout } from 'antd';
import loadable from './loadable';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import LeftNav from './leftNav';
import Routes from './routeConfig';
import defaultlogo from '../../imgs/logo_1_.png';
import {inject, observer} from "mobx-react";
import Success from "../common/Success";

const { Content, Sider} = Layout;

@inject("loginStore")
@observer
@withRouter
class ELayout extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            collapsed: false,
            logo:defaultlogo,
            leftVal:200,
            routes:this.getLicenceRoutes(),

        };
    }


    onCollapse = () => {
        let newcolaps=!this.state.collapsed;
        this.setState({
            collapsed: newcolaps,
            leftVal:newcolaps?80:200,
            logo:newcolaps?'http://rfgzyprod.oss-cn-beijing.aliyuncs.com/user-dir-prefix/logo/mini_web_logo/logomini_demo.png':defaultlogo
        });

    };
    logout=()=>{
        this.props.history.push('/login');
    }

    getRoutes=(routeId,routes) => {
        const {loginStore} =this.props;
        let childRoutes=[];
        let _this=this;
        loginStore.getObject(routeId).then((res)=>{
            if(res.code==0){
                if(res.data.length>0){
                    childRoutes=res.data.map((item)=>{
                        let url=`/admins/admin${routeId}/${item.apiKey}`;
                        let component=loadable(() => import(`../admins/${routeId}/index`));
                        let obj={
                            path: url,
                            pPath: `/admins/admin${routeId}`,
                            isNav: false,
                            id:item.apiKey,
                            name:item.name,
                            text: item.label,
                            component
                        };
                        return obj
                    });
                    // routes.forEach((il,i)=>{
                    //     if(il.id==routeId){
                    //         routes[i].children=childRoutes;
                    //     }
                    // });
                    routes.concat(childRoutes);
                    _this.setState({
                        routes
                    })
                }
            }
        },()=>{
        })
    };
    //根据路由配置文件初始化路由
    getChildRoutes = (children,pid) => {
        return (children).map((cl,c)=>{
            let Component=cl.component;
            return (
                <Route
                    key={cl.path}
                    exact={true}
                    path={cl.path}
                    render={ ()=> {
                        return <Component {...cl} {...this.props} root={'/admins'}/>
                    }}
                />
            )
        })
    };
    getLicenceRoutes=()=>{
        let routes=Routes.routes.filter((il,i)=>{
            if(il.id!=='sign'||Routes.licensePer(Routes.licenses[il.id])){
                return il;
            }
        })
        return routes;

    }
    componentWillMount(){
        // let { routes }=Routes;
        // let _this=this;
        // routes.forEach((item,i)=>{
        //     if(item.id!=='index'&&item.id!=='sign'&&item.id!=='userPermission'){
        //         _this.getRoutes(item.id,routes);
        //     }
        // })
    }
    render=()=>{
        let { routes }=this.state;
        let {logo,collapsed} =this.state;
        // lisicen
        return (
            <Layout className={'global-layout'}>
                <Sider collapsible theme="light" collapsed={collapsed} onCollapse={this.onCollapse} className={'left-wrap'} >
                    <div className="logo">
                        {collapsed?<img src={logo} style={{width:36,height:36}} alt={''}/>:
                            <img src={logo} style={{width:200,height:90}} alt={''}/>}
                    </div>
                    <LeftNav routes={routes} collapsed={collapsed}/>
                    <div className={'user-setting-wrap'} style={{width:collapsed?80:200}} onClick={this.logout}>
                        <span className={'icon'}>退出登录</span>
                    </div>
                </Sider>
                    <Content className={!collapsed?'right-layout right-wrap':'right-layout right-callapse-wrap'} >
                        <Switch>
                            {
                                routes.map((il,i)=>{
                                    // if(il.id==='sign'&&!Routes.licensePer(Routes.licenses[il.id])){
                                    //     return null;
                                    // }

                                    let Component=il.component;
                                    if(il.children.length === 0){
                                        return (
                                            <Route
                                                key={il.path}
                                                exact={true}
                                                path={il.path}
                                                render={ ()=> {
                                                    return <Component {...il} root={'/admins'} />
                                                }}
                                            />
                                        )
                                    }else{
                                        return this.getChildRoutes(il.children,il.id)
                                    }
                                })
                            }
                            <Route path="/admins/success" render={()=>{
                                return <Success {...this.props} root={'/admins'} />
                            }}/>
                            <Redirect to="/admins/adminindex" />
                        </Switch>
                    </Content>
            </Layout>
        )
    }
}
export default ELayout