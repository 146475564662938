import React from 'react';
import {Input, Button, message} from 'antd';
import {observer, inject} from 'mobx-react';
import logo from '../../imgs/login_logo.png';
import './Login.less';

@inject('loginStore')
@observer
class LoginNew extends React.Component{

    state={
        upNameLabel:false,
        upPassLabel:false,
        name:'',
        pass:''
    };

    moveup=(type,e)=>{
        console.log(e,type);
        if(type=='name'){
            this.setState({
                upNameLabel:true,
                name:'',
            })
            this.nameInput.focus();
        }else{
            this.setState({
                upPassLabel:true,
                pass:''
            })
            this.passInput.focus();
        }
    };

    moveIn=(type,e)=>{
        if(type=='name'&&!e.target.value){
            this.setState({
                upNameLabel:false
            })
        };
        if(type=='pass'&&!e.target.value){
            this.setState({
                upPassLabel:false
            })
        };
    };
    changeVal=(type,e)=>{
        type=='name'&&this.setState({
            name:e.target.value
        });

        type=='pass'&&this.setState({
            pass:e.target.value
        })
    };

    handleSubmit = (e) => {

        const {loginStore, history} = this.props,
            {doLogin} = loginStore;
        e.preventDefault();

        // var params = new URLSearchParams();
        let form= new URLSearchParams();
        form.append('account',this.state.name);
        form.append('password',this.state.pass);

        doLogin(form).then((resp) => {
            if (resp.code === 0) {
                window.sessionStorage.setItem('token', resp.data.tokenInfo?resp.data.tokenInfo.access_token:'');
                window.sessionStorage.setItem('tenantId', resp.data.tenantList[0].tenantId);
                window.sessionStorage.setItem('userId', resp.data.tenantList[0].passportId);
                window.sessionStorage.setItem('userName', resp.data.tenantList[0].phone);
                window.sessionStorage.setItem('licenses', resp.data.tenantList[0].licenses);
                history.push('/admins/adminindex');
            } else {
                message.error(resp.msg);
            }
        }, (resp) => {
            message.error(resp.msg)
            console.log('error', resp);
        });
    };
    componentDidMount(){
        window.sessionStorage.setItem('token', '');
        window.sessionStorage.setItem('tenantId', '');
        window.sessionStorage.setItem('userId', '');
        window.sessionStorage.setItem('userName', '');
        window.sessionStorage.setItem('licenses', '');
    }

    render(){
        let {upNameLabel,upPassLabel,name,pass} = this.state;
        return (
            <div className="login-wrap">
                <div className={'left-wrap'}>
                    <div className={'left-bg-box'}></div>
                </div>
                <div className="right-wrap">
                    <div className={'right-all'}>
                        <div className={'right-form'}>
                            <div className={'form-content'}>
                                <div className={'logo-wrap'}>
                                    <img src={logo} />
                                </div>
                                <div className={'login-title'}>
                                    登录
                                </div>
                                <div>
                                    <div className={'login-input-wrap'}>
                                        <div className={'input-group'} onClick={this.moveup.bind(this,'name')}>
                                            <label className={'fake-placeholder'} style={{'bottom':upNameLabel?40:12}} >手机号/邮箱</label>
                                            <Input autoComplete='off' ref={(input) => { this.nameInput = input; }} onChange={this.changeVal.bind(this,'name')} value={name} onBlur={this.moveIn.bind(this,'name')} onFocus={this.moveup.bind(this,'name')} type={'text'} size={'large'} maxLength={50} disabled={false} allowClear={true} />
                                        </div>

                                    </div>
                                    <div className={'login-input-wrap'}>
                                        <div className={'input-group'} onClick={this.moveup.bind(this,'pass')}>
                                            <label className={'fake-placeholder'} style={{'bottom':upPassLabel?40:12}}>密码</label>
                                            <Input autoComplete='off' ref={(input) => { this.passInput = input; }} onChange={this.changeVal.bind(this,'pass')} value={pass} onBlur={this.moveIn.bind(this,'pass')} onFocus={this.moveup.bind(this,'pass')} type={'password'} size={'large'} maxLength={50} disabled={false}  allowClear={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className={'login-btn'}>
                                    <Button type={'primary'} size={'large'} htmlType={'button'} block={true} onClick={this.handleSubmit.bind(this)}>登录</Button>
                                </div>
                            </div>
                        </div>
                        <div className={'right-footer'}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginNew