import {observable, action} from 'mobx';
import getJSON from '../../../api/url.js';

class DepartIndexStore {
    @observable compTree;
    @observable password;
    @observable selectedKeys;

    @observable btnLoading;
    @observable modalTitle;

    constructor() {
        this.compTree=null;
        this.selectedKeys=[];
        this.modalTitle='新增分公司或部门';
        this.btnLoading=false;
    }

    @action getTreeComp= ()=>{
        return getJSON({
            urlKey:'comp',
            method:'get',
        }).then((resp)=>{
            this.compTree=resp.data;
            this.selectedKeys=[resp.data.id];
        },(resp)=>{

        })
    };

    @action setSelectedKeys=(selectedKeys)=>{
        this.selectedKeys=selectedKeys;
    }

    @action compAdd=()=>{

    }
}

const departIndexStore = new DepartIndexStore();//通过new 创建一个homeStore对象实例通过export导出
export default departIndexStore;
export {departIndexStore}