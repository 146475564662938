import {observable, action} from 'mobx';

import getJSON from '../../api/url.js';

class AccountBillStore{
    @observable businessType;
    @observable dataList;
    @observable loading;
    @observable current;
    @observable total;
    @observable size;
    @observable activeTab;
    @observable detailData;



    constructor(){
        this.businessType='ESIGN';
        this.setInitState();
    }
    @action setInitState=(isreset)=>{
        this.current=1;
        this.total=0;
        this.size=10;
        this.dataList=[];
        this.loading=false;
        this.activeTab=1;
        this.detailData={}
    }
    @action searchHandle=(pageObject)=>{
        this.current=pageObject?pageObject.current:1;
        this.size=pageObject?pageObject.pageSize:20;
        let param=this.getSearchParam();
        if(this.id){
            this.getCostDetail(param)
        }else{
            this.activeTab==1?this.getCostList(param):this.getBuyList(param);
        }

    };

    @action getSearchParam=()=>{
        return {
            pageNo:this.current,
            pageSize:this.size,
            businessType:this.businessType
        }
    };



    @action changeTabs=(key)=>{
        this.activeTab=key;
        this.setInitState(true);
        if(key==1){
            this.getCostList();
        }else{
            this.getBuyList();
        }
    };

    @action getCostList=(param)=>{
        this.loading=true;
        return getJSON({
            urlKey:'costList',
            method:'get',
            encodeData:param||{
                pageNo:this.current,
                pageSize:this.size,
                businessType:this.businessType
            },

        }).then((res)=>{
            this.dataList=res.data.records;
            this.total=res.data.total;
            this.size=res.data.size;
            this.current=res.data.current;
            this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    };

    @action getBuyList=(param)=>{
        this.loading=true;
        return getJSON({
            urlKey:'buyList',
            method:'get',
            encodeData:param||{
                pageNo:this.current,
                pageSize:this.size,
                businessType:this.businessType
            },

        }).then((res)=>{
            this.dataList=res.data.records;
            this.total=res.data.total;
            this.size=res.data.size;
            this.current=res.data.current;
            this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    };

    @action setDetailid=(id)=>{
        this.id=id;
        this.getCostDetail();
    }

    @action getCostDetail=(param)=>{
        let _this=this;
        this.loading=true;
        getJSON({
            urlKey:'costDetail',
            method:'get',
            urlData:[this.id],
            encodeData:param||{
                pageNo:this.current,
                pageSize:this.size,
                businessType:this.businessType
            },
        }).then((res)=>{
            _this.detailData=res.data;
            let listRes=res.data.consumePackageRecords;
            _this.dataList=listRes.records;
            _this.total=listRes.total;
            _this.size=listRes.size;
            _this.current=listRes.current;
            _this.loading=false;

        },(res)=>{})
    }

    @action setBusinessType=(businessType)=>{
        this.businessType=businessType;
        this.getCostList()
    }


}

const accountBillStore = new AccountBillStore();
export default accountBillStore;
export {accountBillStore}