import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Layout,Table,Button,Card,Popconfirm,message} from "antd";
import {observer, inject} from 'mobx-react';
import HeaderContent from '../../../../modules/layouts/headerContent';
import AddOwnerForm from './AddownerStamp';

const {Content} = Layout;

@inject('stampOperateStore')
@observer
class Stamps extends Component{

    store=this.props.stampOperateStore;
    state = {
        visible: false,
    };

    showModal = () => {
        this.setState({ visible: true });
        this.store.getPartnersListAll();
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleCreate = () => {
        let _this=this;
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            if(this.store.targetKeys.length==0){
                message.warn('请至少选择一个持有人！')
                return;
            }
            console.log('Received values of form: ', values);
            debugger;
            this.store.addOwers(values.items).then((res)=>{
                form.resetFields();

                _this.setState({ visible: false });
                _this.store.getDetailList();
                _this.store.targetKeys=[];
                message.success(res.msg)
            },(res)=>{
                message.error(res.msg)
            })

        });
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    deleteItem=(id)=>{
        let _this=this;
        this.store.deleteStamp(id).then((res)=>{
            message.success('删除成功！');
            _this.store.getDetailList();
        },(res)=>{
            message.error(res.msg)
        })
    };

    getColumn=()=>{
        let _this=this;
        let columns=[
            {
                title:'印章持有人',
                dataIndex:'userAccountName',
                // width:'20%',
            },
            {
                title:'账号',
                dataIndex:'userAccount',
                // width:'20%',
            },
            // {
            //     title:'部门',
            //     dataIndex:'userDeptName',
            // },
            {
                title:'手机号',
                dataIndex:'userPhone',
                // width:'20%',
            },
            {
                title:'操作',
                dataIndex:'operate',
                render:(text, record, index)=>{
                    return (
                        <Popconfirm title="你确定要删除吗?" onConfirm={_this.deleteItem.bind(_this,record.id)} okText="确定" cancelText="取消">
                            <Button type={'link'} size={'small'} icon={<LegacyIcon type={'delete'} />}>删除</Button>
                        </Popconfirm>
                    );
                }
            },
        ];
        return columns
    };


    render(){
        const {root} =this.props;
        let {ownersList,loading,current,size,total,signatures,searchHandle,partners,targetKeys,handleTargetChange} =this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} title={this.props.text}>
                    <Button type={'link'} size={'small'} icon={<LegacyIcon type={'arrow-left'} />} style={{float:'right',marginTop:10,marginRight:30}} onClick={()=>{
                        this.props.history.goBack()
                    }}>返回</Button>
                </HeaderContent>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <div className={'pd-10 text-right flex space-between m-b-20'} style={{borderBottom:'1px solid #ececec'}}>
                        <span>印章详情</span>
                        <Button type={'primary'} size={'small'} onClick={this.showModal}>新增持有人</Button>
                    </div>
                    <div style={{display:'flex'}}>
                        <Card title={(<div>
                            <span className={'text-gray fz-12'}>印章名称：</span>
                            <div className={'text-black'}>{signatures.signatureName}</div>
                        </div>)} style={{ width: 300,marginRight:30 }}>
                            <p className={'text-gray'}>印章图片</p>
                            <img src={'data:image/png;base64,'+signatures.signatureImgBase64} alt={'印章图片'} width={'90%'}/>
                        </Card>
                        <Table className={'m-b-10'} style={{flex:1}} loading={loading}
                               columns={this.getColumn()} rowKey={'id'} dataSource={ownersList} size={'small'}
                               total={total}
                               onChange={(pagination, filters, sorter)=>{
                                   searchHandle(pagination);
                               }}
                               pagination={false}
                               // pagination={{
                               //     showSizeChanger:true,
                               //     showQuickJumper:true,
                               //     defaultCurrent:1,
                               //     current:current,
                               //     pageSize:size,
                               //     total:total,
                               // }}
                        />
                    </div>
                </Content>
                <AddOwnerForm wrappedComponentRef={this.saveFormRef}
                              visible={this.state.visible}
                              onCancel={this.handleCancel}
                              onCreate={this.handleCreate}
                              handleTargetChange={handleTargetChange}
                              data={partners}
                              targetKeys={targetKeys}
                />
            </Layout>
        );
    }

    componentWillMount(){
        const {history,location} =this.props;
        if(!location.state||!location.state.id){
            message.error('没有传递ID！');
            history.back();
            return;
        }
        this.store.setSignatureId(location.state)
    }
}

export default Stamps