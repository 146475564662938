import React ,{Component} from 'react';
import {message} from 'antd';


export default class TemplateWrap extends Component{
    state={
        fadada:''
    }

    componentWillMount(){
        const {location,history} =this.props;
        if(!location.state){
            history.goBack();
            message.warn('没有返回正确的地址！');
            return;
        };
        this.fadada=location.state['fadada'];

    }

    render(){
        return <iframe src={this.fadada} style={{width:'100%',height:'100vh',border:'none'}}></iframe>
    }
}