import React,{ Component } from 'react';
import { Radio ,Popover,List} from 'antd';
import imgsrc from '../../imgs/login_logo.png';
import './fields.less';

export default class FieldType extends Component{

    constructor(props){
        super();
        this.state= {
            selectItem: props.selectType
        }
    }

    changeSelect= (item)=>{
        const {handleFieldType} = this.props;
        this.setState({
            selectItem:item
        });
        handleFieldType&&handleFieldType(item);
    }

    render(){
        const {types,selectType} =this.props;
        return (
            <div className={'new-field-type-wrap'}>
                <h3>选择字段类型：</h3>
                <List
                    size="small"
                    className={'types-wrap'}
                    bordered
                    dataSource={types}
                    renderItem={
                        item => <List.Item onClick={this.changeSelect.bind(this,item)} className={selectType.type==item.type?'activeType':''}>{item.text}</List.Item>}
                />
                <h3 className={'m-t-10'}>示例：</h3>
                <img src={selectType.imgSrc} className={'field-type-img'} />
            </div>
        )
    }


}