import React from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Button, Slider } from 'antd';
import './cropper.less'

const loadImg = (src) => {
    const paths = Array.isArray(src) ? src : [src];
    const promise = [];
    paths.forEach((path) => {
        promise.push(new Promise((resolve, reject) => {
            let img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = path;
            img.onload = () => {
                resolve(img);
            };
            img.onerror = (err) => {
                console.log('图片加载失败')
            }
        }))
    });
    return Promise.all(promise);

}

export default class CanvasImg extends React.Component{
    state={
        opacity:1,
        rotateAngle:0,
        scale:1,
        imgobj:null,
        ctx:null,
        imageURL:'',

        width:166,
        height:166,
    }
    transparentImageData = (opacity,a,b) => {
        let {ctx ,imgobj,width,height,scale}= this.state;
        ctx.clearRect(0,0,width,height);
        let imgh = width * scale;
        let imgw = height * scale;
        let x = width / 2 - imgw / 2;
        let y = height / 2 - imgh / 2;

        ctx.drawImage(imgobj, a||x, b||y, imgw, imgh);
        this.handlePoint(opacity);
    };
    handlePoint=(opacity)=>{
        let {ctx ,width,height}= this.state;
        let imgPoints=ctx.getImageData(0, 0, width, height);
        let data=imgPoints.data;
        for(let i = 0; i < data.length; i+=4) {
            // 得到 RGBA 通道的值
            let r = data[i]
                , g = data[i+1]
                , b = data[i+2]

            // 我们从最下面那张颜色生成器中可以看到在图片的右上角区域，有一小块在
            // 肉眼的观察下基本都是白色的，所以我在这里把 RGB 值都在 245 以上的
            // 的定义为白色
            // 大家也可以自己定义的更精确，或者更宽泛一些
            // if(this.state.opacity>opacity){
            if([r,g,b].every(v => v < 256 && v > 245)) data[i+3] = (opacity/1)*225
            // }

        }
        ctx.putImageData(imgPoints, 0, 0);
        this.trans2Img()
    }

    onChangeOpacity=(value)=>{
        let {ctx,scale,width,height,rotateAngle} =this.state;
        ctx.save();
        ctx.clearRect(0,0,width,height);
        this.setState({
            opacity: value,
        });
        this.transparentImageData(value);
        this.rotateCanvas(rotateAngle,scale,value);
        ctx.restore();
    }

    rotateCanvas = (rotateAngle,scale,opacity)=>{
        let {ctx ,width,height}= this.state;
        ctx.save();
        ctx.clearRect(0,0,width,height);
        ctx.translate((width/2),(height/2));
        ctx.rotate(rotateAngle*Math.PI/180);
        this.transparentImageData(opacity,-(width*scale/2),-(height*scale/2));
        ctx.rotate(-(rotateAngle*Math.PI/180));
        ctx.translate(-width/2, -height/2);
        ctx.restore();
    }

    rotateImgData = ()=>{
        let rotateAngle=this.state.rotateAngle+10;
        this.setState({
            rotateAngle:rotateAngle
        });
        this.rotateCanvas(rotateAngle,this.state.scale,this.state.opacity);
    };

    scaleCanvas = (scale)=>{
        let {ctx ,imgobj,width,height}= this.state;
        this.setState({
            scale
        });
        let imgh = width * scale;
        let imgw = height * scale;
        let x = width / 2 - imgw / 2;
        let y = height / 2 - imgh / 2;
        ctx.save();
        ctx.drawImage(imgobj , x , y ,imgw , imgh);
        this.handlePoint(this.state.opacity);
        ctx.restore();
    }

    scaleImaData = (type)=>{
        let scale=0;
        if(type=='in'){
            scale=this.state.scale+0.2
        }else{
            scale=this.state.scale==1?1:this.state.scale-0.2
        }
        this.scaleCanvas(scale)
    };

    trans2Img=()=>{
        let {canvas}= this.state;
        let imageURL = canvas.toDataURL("image/png");
        this.setState({
            imageURL:imageURL
        });
        this.props.passUrl(imageURL);
    };

    initCanvas=(file)=>{
        let _this=this;
        let canvas =null,ctx=null;
        if(!this.state.canvas){
            canvas = this.refs.canvas
            ctx = canvas.getContext("2d");
        }else{
            canvas = this.state.canvas;
            ctx=this.state.ctx;
        }

        ctx.clearRect(0,0,canvas.width,canvas.height);
        let reader = new FileReader();
        reader.readAsDataURL(file); //读取文件并将文件以URL的形式保存在resulr属性中 base64格式
        reader.onload = function(e) { // 文件读取完成时触发
            let result = e.target.result; // base64格式图片地址
            let image = new Image();
            image.src = result; // 设置image的地址为base64的地址
            image.onload = function(){
                ctx.drawImage(image, 0, 0, _this.state.width, _this.state.height); // 在canvas上绘制图片
                _this.setState({
                    imgobj:image,
                    ctx,
                    canvas,
                    opacity:1,
                    rotateAngle:0,
                    scale:1,
                });
                _this.trans2Img()
            }

        }
    };

    componentDidMount(){
        const file=this.props.file;
        this.initCanvas(file);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.file!==this.props.file){
            this.initCanvas(nextProps.file)
        }
    }

    render(){
        const {opacity,width,height} =this.state;
        return (
            <div className="canvas-img-wrap">
                <div className="seal-add-background">
                    <div className="seal-add-img" style={{display: 'block'}} id="seal-view-img">
                             {/*<img  alt='mix_img' src={imageURL} ref='mix_img' style={{*/}
                                 {/*position:'absolute',*/}
                                 {/*width:canvas.width,*/}
                                 {/*height:canvas.height,*/}
                                 {/*top:(340/2)-((canvas.height)/2),*/}
                                 {/*left:(598/2)-(canvas.width/2),*/}
                             {/*}}/>*/}
                        <canvas ref={'canvas'} width={width} height={height} style={{
                            position:'absolute',
                            top:(340/2)-(width/2),
                            left:(598/2)-(height/2),
                        }} />
                    </div>
                </div>
                <div className={'operate-canvas'}>
                    <div className={'flex'}>
                        <span>背景透明度：</span>
                        <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            style={{width:100}}
                            onChange={this.onChangeOpacity}
                            value={typeof opacity === 'number' ? opacity : 1}
                        />
                        <span>{opacity*100}%</span>
                    </div>
                    {/*<Icon type={'reload'} size={18} onClick={this.rotateImgData}/>*/}
                    <span >
                        <LegacyIcon className={'m-r-10'} type={'zoom-in'} size={18} onClick={this.scaleImaData.bind(this,'in')}/>
                        <LegacyIcon type={'zoom-out'} size={18} onClick={this.scaleImaData.bind(this,'out')}/>
                    </span>

                </div>
            </div>
        );
    }
}