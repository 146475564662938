import React from 'react';
import {ConfigProvider} from 'antd';
import {Provider} from "mobx-react";
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import zh_CN from 'antd/lib/locale/zh_CN';
import stores from './store/mapStore';
import LoginNew from './modules/login/LoginNew';
import ELayout from './modules/layouts/Layout';
import GzyModules from './modules/layouts/gzyModules';
import AuthorizedRoute from './modules/login/AuthorizedRoute';
// import Success from './modules/common/Success'
import 'moment/locale/zh-cn';

class App extends React.Component{
  render(){
      return (
          <ConfigProvider locale={zh_CN}>
              <Provider {...stores}>
                  <BrowserRouter basename="/">
                      <Switch>
                          <Route path="/login" component={LoginNew} />
                          {/*<Route path="/success" component={Success}/>*/}
                          <AuthorizedRoute path="/admins" component={ELayout} />
                          <AuthorizedRoute path="/modules" component={GzyModules} />
                          <Redirect to="/login" component={LoginNew}/>
                      </Switch>
                  </BrowserRouter>
              </Provider>
          </ConfigProvider>
      );
  }

}

export default App;
