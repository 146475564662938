import {observable, action} from 'mobx';
import getJSON from '../../api/url.js';

class StampOperateStore{
    @observable ownersList;
    @observable loading;
    @observable current;
    @observable total;
    @observable size;
    @observable signatures;

    @observable signatureImgBase64;
    @observable imgloading;
    @observable type;
    @observable id;

    @observable targetKeys;
    @observable partners;


    constructor(){
        this.setInitState();
    }
    @action setInitState=(isreset)=>{
        this.current=1;
        this.total=0;
        this.size=10;
        this.ownersList=[];
        this.loading=false;
        this.signatures={};

        this.signatureImgBase64='';
        this.imgloading=false;
        this.type='create';
        this.id='';

        this.targetKeys=[];
        this.partners=[];
    };
    @action setSignatureId=(obj)=>{
        this.signatureId=obj.id;
        this.signatures=obj;
        this.getDetailList();
        this.getStampInfo();
    };

    @action getStampInfo=(id)=>{
        let _this=this;
        getJSON({
            urlKey:'getDetailStampInfo',
            method:'get',
            encodeData:{
                id:id||this.signatureId
            }
        }).then((res)=>{
            _this.signatures=res.data;
            if(_this.type=='edit'){
                _this.signatureImgBase64=res.data.signatureImgBase64;
            }
        })
    }

    @action searchHandle=(pageObject)=>{
        this.current=pageObject?pageObject.current:1;
        this.size=pageObject?pageObject.pageSize:20;
        this.getDetailList();
    };

    @action getDetailList=()=>{
        let _this=this;
        this.loading=true;
        return getJSON({
            urlKey:'signatureUsers',
            method:'get',
            encodeData:{
                // current:this.current,
                // size:this.size,
                signatureId:this.signatureId
            }
        }).then((res)=>{
            _this.ownersList=res.data;
            // let owerkeys=[];
            // res.data.forEach((il,i)=>{
            //     owerkeys.push(il.accountId)
            // });
            // _this.targetKeys=owerkeys;
            // this.total=res.data.total;
            // this.size=res.data.size;
            // this.current=res.data.current;
            _this.loading=false;
            return res
        },(res)=>{
            _this.loading=false;
            return res;
        })
    };

    @action deleteStamp=(id)=>{
        return getJSON({
            urlKey:'deleteUserSignature',
            method:'delete',
            encodeData:{
                id
            }
        })
    };

    @action setType=(state)=>{
        this.type=state.type;
        if(state.id){
            this.id=state.id;
            this.getStampInfo(this.id);
            this.signatureName=state.signatureName;

        }
    };

    @action setImgUrl=(url)=>{
        if(url.indexOf('data:image/png;')!=-1){
            let strarr=url.split('data:image/png;base64,');
            url=strarr[1];
        }
        this.signatureImgBase64=url;
        this.imgloading=false;
    };

    @action addSignature=(data)=>{
        if(this.type==='edit'){
            data.id=this.id;
            return getJSON({
                urlKey:'updateSignature',
                method:'post',
                data
            })
        }
        
        return getJSON({
            urlKey:'addSignature',
            method:'post',
            data
        })
    }

    @action getPartnersListAll=()=>{
        
        return getJSON({
            urlKey:'partnersListAll',
            method:'get',
            encodeData:{
                signatureId:this.signatureId
            }
        }).then((res)=>{
            this.partners=res.data.records;
            this.targetKeys=this.targetKeys.slice();
        
            return res
        },(res)=>{
            return res;
        })
    };

    @action handleTargetChange=(targetKeys, direction, moveKeys)=>{
        this.targetKeys=targetKeys;
    }

    @action addOwers=()=>{
        return getJSON({
            urlKey:'chooseAddOwner',
            method:'post',
            data:{
                accountIds:this.targetKeys,
                signatureId:this.signatureId
            }
        })
    }

}

const stampOperateStore = new StampOperateStore();
export default stampOperateStore;
export {stampOperateStore}