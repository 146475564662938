import React ,{Component } from 'react';
import {DatePicker} from 'antd';
import './gzydaterange.less'

export default class GzyDateRangePicker extends Component{
    state = {
        Bgn: null,
        End: null,
        endOpen: false,
    };

    disabledStartDate = startValue => {
        const { End } = this.state;
        if (!startValue || !End) {
            return false;
        }
        return startValue.valueOf() > End.valueOf();
    };

    disabledEndDate = endValue => {
        const { Bgn } = this.state;
        if (!endValue || !Bgn) {
            return false;
        }
        return endValue.valueOf() <= Bgn.valueOf();
    };

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
        this.props.rangeChange(field,value)
    };

    onStartChange = value => {
        this.onChange('Bgn', value);
    };

    onEndChange = value => {
        this.onChange('End', value);
    };

    handleBgnOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };
    componentWillReceiveProps =nextProps => {
        if(nextProps){
            this.setState({
                Bgn: nextProps.Start,
                End: nextProps.End,
            })
        }
    }

    render(){
        const { Bgn, End, endOpen } = this.state;
        let {placeholder,showTime}=this.props;
        return (
            <div className={'flex ui-gzydaterange'}>
                <DatePicker
                    disabledDate={this.disabledStartDate}
                    showTime={showTime}
                    // format="YYYY-MM-DD"
                    value={Bgn}
                    placeholder={placeholder?placeholder[0]:"开始时间"}
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                />
                <span className={'pdlr-5'}>至</span>
                <DatePicker
                    disabledDate={this.disabledEndDate}
                    showTime={showTime}
                    // format="YYYY-MM-DD"
                    value={End}
                    placeholder={placeholder?placeholder[1]:"结束时间"}
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                />
            </div>
        )}
}