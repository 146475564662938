import React from 'react';
import {Layout} from 'antd';
import {inject, observer} from 'mobx-react';
const { Header } = Layout;

@inject("loginStore")
@observer
class HeaderContent extends React.Component {

    render() {
        return (
            <Header
                className={`right-wrap-admin-header`}
                style={{ }}>
                <strong style={{'paddingLeft':20}}>{this.props.title?this.props.title:`${this.props.text}管理`}</strong>
                {this.props.children||null}
            </Header>
        )
    }
}

export default HeaderContent


