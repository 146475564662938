import React from "react";
import fetch from "isomorphic-fetch";
import getJSON from '../../api/url.js';

export default class UploadOss extends React.Component {
    clickUpload = () => {
        if(!this.props.canChoose){
            return ;
        }
        let input = document.createElement('input')
        let inputBox = document.createElement('div')
        input.setAttribute('type','file');
        if(this.props.multiple){
            input.setAttribute('multiple',true);
        };
        input.onchange = (e)=>{
            this.props.showLoading(this.props.type);
            this.doUpload()
        }
        inputBox.style.height = 0;
        inputBox.style.overflow = 'hidden';
        inputBox.appendChild(input)
        document.body.appendChild(inputBox)
        this.input = input
        this.inputBox = inputBox
        input.click()
    }
    doUpload = () => {
        console.log("files",this.input.files);
        const files=this.input.files;
        const {url, upload} = this.props;
        this.inputBox.parentNode.removeChild(this.inputBox);
        this.input = null;
        this.inputBox = null;
        getAliOssInfo(url).then(rst => {
               for(let i in files) {
                   if(i==="length"||i==="item"){
                       return;
                   }
                   let file=files[i];
                   if (upload) {
                       upload(rst, file, this.upLoadAlioss,i);
                   }else{
                       this.upLoadAlioss(Object.assign({}, rst, {file}))
                   }
               }
        })


        // const file = this.input.files[0];
        // const {url, upload} = this.props;
        // this.inputBox.parentNode.removeChild(this.inputBox);
        // this.input = null;
        // this.inputBox = null;
        // getAliOssInfo(url).then(rst => {
        //     if (upload) {
        //         upload(rst, file, this.upLoadAlioss)
        //         return
        //     }
        //     this.upLoadAlioss(Object.assign({}, rst, {file}))
        // })
    }
    upLoadAlioss = (options) => {
        let that=this;
        const file = options.file;
        let curDate = new FormData();
        let time=new Date().getTime();
        const key = options.dir + time+ '_' + options.file.name;
        const keyimg=time+'_' + options.file.name;
        curDate.append("OSSAccessKeyId", options.accessid);
        curDate.append("policy", options.policy);
        curDate.append("Signature", options.signature);
        curDate.append("key", key);
        curDate.append("success_action_status", '200');
        curDate.append('file', file);
        curDate.append('expire', options.expire);
        const Token = window.sessionStorage.getItem('token');
        let defaultConfig = {
            headers: {
                'X-Rental-Token': Token || '',
                'tenantId':window.sessionStorage.getItem('tenantId')
            },
        };
        return fetch(options.host, {
            method: 'post',
            body: curDate,
        },defaultConfig).then(() => {
            let url=options.host +'/'+options.dir +keyimg;
            that.props.uploadEnd(url)
        }).catch((err) => {
            that.props.uploadEnd(false)
        })
    }

    render() {
        const {children} = this.props;
        return (
            <div className="react-alioss-upload-box">
                <div onClick={this.clickUpload.bind(this)}>
                    {children}
                </div>
            </div>
        )
    }
}

function getAliOssInfo(url) {
    return getJSON({
        urlKey:url,
        method:'get'
    }).then((rst)=>{
        if(Number(rst.code)===0){
            return rst
        }else{
            return false
        }
    });
}
