import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tabs,Button } from 'antd';

const { TabPane } = Tabs;
export default class SettingLayout extends Component{
    constructor(prop){
        super();
        this.state={
            activeTab:prop.defaultActiveTab
        }
    }

    changeTab=(e)=>{
        this.setState({
            activeTab:e
        })
    };

    render(){
        const { standTitle,tabsData } =this.props;


        return (
            <div >
                <h1 className={'title'}><span>详情设置</span></h1>
                <Tabs className={'bg-content'} onChange={this.changeTab} type="card" activeKey={this.state.activeTab}>
                    {tabsData.map((tab,i)=>{
                        return <TabPane tab={tab.tabTitle} key={tab.key}>
                                {tab.tabContent()}
                        </TabPane>
                    })}
                </Tabs>
            </div>
        );
    }
}