import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Table, Button, Popconfirm, message, Select, Row, Col, Input } from "antd";
import {observer, inject} from 'mobx-react';
import moment from 'moment';
import GzyDateRangePicker from '../../../../components/gzyDateRangePicker';
import HeaderContent from '../../../../modules/layouts/headerContent';
import withTrim from "../../../../highGradeComps/trimwrap";
const Option = Select.Option;
const {Content} = Layout;
const TrimeInput=withTrim(Input);

@inject('templateListStore')
@observer
class Templates extends Component{

    store=this.props.templateListStore
    goEdit=(item)=>{
        const {root}=this.props;
        let pathname='/admins/sign/addTpl';
        if(root=="/modules"){
            pathname=root+'/sign/addTpl'
        }
        this.props.history.push({pathname:pathname,state:{type:'edit',id:item.id}})
    };
    goAdd=()=>{
        const {root}=this.props;
        let pathname='/admins/sign/addTpl';
        if(root=="/modules"){
            pathname=root+'/sign/addTpl'
        }
        this.props.history.push({pathname:pathname,state:{type:'create'}})
    };

    deleteItem=(id)=>{
        let _this=this;
        this.store.deleteContractTemplate(id).then((res)=>{
            message.success('删除成功！');
            _this.store.getTemplateList();
        },(res)=>{
            message.error(res.msg)
        })
    };

    showImported=(item)=>{

    };
    changeCreate=(field,value)=>{
        this.store.setDate('createAt',field,value)
    };

    changeUpdate=(field,value)=>{
        this.store.setDate('updateAt',field,value)
    };

    getColumn=()=> {
        let _this=this;
        let columns=[
            {
                title:'实体名称',
                dataIndex:'objectName',
                width:'20%',
            },
            {
                title:'模版名称',
                dataIndex:'templateName',
                width:'20%',
            },
            {
                title:'模板描述说明',
                dataIndex:'templateDesc',
                width:'20%',
                render:(text, record, index)=>{
                    return text
                }
            },
            {
                title:'创建时间',
                dataIndex:'createdAt',
                render:time => time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-',
            },
            {
                title:'更新时间',
                dataIndex:'updatedAt',
                render:time => time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-',
            },
            {
                title:'操作',
                dataIndex:'operate',
                render:(text, record, index)=>{
                    return (
                        <div>
                            <LegacyIcon type={'edit'} onClick={_this.goEdit.bind(_this,record)} className={'m-r-15 text-blue'}/>
                            <Popconfirm title="你确定要删除吗?" onConfirm={_this.deleteItem.bind(_this,record.id)} okText="确定" cancelText="取消">
                                <LegacyIcon type={'delete'} className={'m-r-15 text-red'}/>
                            </Popconfirm>
                        </div>
                    );
                }
            },
        ];
        return columns
    };
    searchHandle=(type,val)=>{
        this.store[type]=val;
    }
    searchBlock= ()=>{
        let {objectList=[],objectId='',templateName='',clearSearchList,searchHandle,createAtStart,createAtEnd,updateAtStart,updateAtEnd} =this.store;
        return (<div>
                <Row gutter={8} style={{marginBottom:10}} justify={'space-between'}>
                    <Col xs={3}>
                        <Select style={{minWidth:'75%'}} placeholder={'请选择实体名称'} value={objectId||undefined} onChange={(val)=>{this.searchHandle('objectId',val)}}>
                            {objectList.map((il,i)=>{
                                return <Option value={il.relateObjectId} key={il.objectId}>{il.name}</Option>
                            })}
                        </Select>
                    </Col>
                    <Col xs={3}>
                        <TrimeInput style={{width:'75%'}} placeholder={'请输入模版名称'} value={templateName||undefined} onChange={(val)=>{this.searchHandle('templateName',val.target.value)}} />
                    </Col>
                    {/*<Col xs={6}>*/}
                        {/*<GzyDateRangePicker Start={createAtStart} End={createAtEnd} showTime={false} rangeChange={this.changeCreate}/>*/}
                    {/*</Col>*/}
                    {/*<Col xs={6}>*/}
                        {/*<GzyDateRangePicker Start={updateAtStart} End={updateAtEnd} isclearTime showTime={false} rangeChange={this.changeUpdate}/>*/}
                    {/*</Col>*/}
                    <Col xs={18} className="" >
                        <Button type="default" onClick={clearSearchList}>重置</Button>
                        <Button type="primary" onClick={()=>{searchHandle(null)}} style={{marginLeft:20}}>查询</Button>
                    </Col>
                </Row>

            </div>

        )
    }

    render(){
        const {root} =this.props;
        let {tplList,loading,current,size,total,searchHandle} =this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} text={this.props.text}/>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <div className={'pd-10 text-right'}>
                        <Button type={'primary'} size={'small'} onClick={this.goAdd}>新建模版</Button>
                    </div>
                    {this.searchBlock()}

                    <Table className={'m-b-10'} loading={loading}
                           columns={this.getColumn()} rowKey={'id'} dataSource={tplList} size={'small'}
                           total={total}
                           onChange={(pagination, filters, sorter)=>{
                               searchHandle(pagination);
                           }}
                           pagination={{
                               showSizeChanger:true,
                               showQuickJumper:true,
                               defaultCurrent:1,
                               current:current,
                               pageSize:size,
                               total:total,
                           }} />
                </Content>
            </Layout>
        )
    }

    componentDidMount(){
        console.log(222);
        // window.addEventListener('message',function(event){
        //     debugger;
        //     console.log('message=>=======',event);
        // }, false)
        console.log('params======',this.props.location.search);
        const {root} =this.props;
        let search=this.props.location.search;
        if(!search){
            this.store.getTemplateList().then((res)=>{
                if(res.code!=0){
                    message.error(res.msg)
                }
            });
            this.store.getObject();
            return;
        }

        const paramsString = search.substring(1);
        const searchParams = new URLSearchParams(paramsString);
        if(searchParams.get('success')){
            // message.success('模版创建成功！');
            this.store.getTemplateList().then((res)=>{
                if(res.code!=0){
                    message.error(res.msg)
                }
            });
            this.store.getObject();
            return;
        }
        if(root==="/modules"){
            const token = searchParams.get('token');
            const tenantId = searchParams.get('tenantId');
            window.sessionStorage.setItem("token",token);
            window.sessionStorage.setItem("tenantId",tenantId);
        }


        this.store.getTemplateList().then((res)=>{
            if(res.code!=0){
                message.error(res.msg)
            }
        });
        this.store.getObject();
    }
    componentWillUnmount(){
        this.store.setInitState();
    }
}

export default Templates
