import {observable, action} from 'mobx';
import getJSON from '../../api/url.js';
import moment from 'moment'

class TemplateListStore{
    @observable tplList;
    @observable loading;
    @observable current;
    @observable total;
    @observable size;

    @observable objectList;
    @observable objectId;
    @observable templateName;
    @observable createAtStart;
    @observable createAtEnd;
    @observable updateAtStart;
    @observable updateAtEnd;
    @observable isclearTime;

    constructor(){
        this.setInitState();
    }
    @action setInitState=(isreset)=>{
        this.current=1;
        this.total=0;
        this.size=10;
        this.objectId='';
        this.templateName='';
        // this.createAtStart=null;
        // this.createAtEnd=null;
        // this.updateAtStart=null;
        // this.updateAtEnd=null;
        if(isreset){
            return;
        }
        this.tplList=[];
        this.objectList=[];
        this.loading=false;
        this.tenantId=window.sessionStorage.getItem('tenantId')
    }
    @action searchHandle=(pageObject)=>{
        this.current=pageObject?pageObject.current:1;
        this.size=pageObject?pageObject.pageSize:20;
        let param=this.getSearchParam();
        this.getTemplateList(param);

    };

    @action getSearchParam=()=>{
        return {
            current:this.current,
            size:this.size,
            tenantId:window.sessionStorage.getItem('tenantId'),
            objectId:this.objectId,
            templateName:this.templateName,
            // createdAtBgn:this.createAtStart?moment(this.createAtStart.valueOf()).startOf('day').valueOf():'',
            // createdAtEnd:this.createAtEnd?moment(this.createAtEnd.valueOf()).startOf('day').valueOf():'',
            // updateAtEnd:this.updateAtEnd?moment(this.updateAtEnd.valueOf()).startOf('day').valueOf():'',
            // updateAtBgn:this.updateAtStart?moment(this.updateAtStart.valueOf()).startOf('day').valueOf():''
        }
    };

    @action setDate=(type,field,value)=>{
        // this[`${type}${field}`]=value;
    };

    @action clearSearchList=()=>{
        this.setInitState(true);
        this.getTemplateList(this.getSearchParam());
    };

    @action getTemplateList=(param)=>{
        this.loading=true;
        return getJSON({
            urlKey:'ContractTemplateList',
            method:'get',
            encodeData:param||{
                current:this.current,
                size:this.size,
                tenantId:window.sessionStorage.getItem('tenantId')
            }
        }).then((res)=>{
            this.tplList=res.data.records;
            this.total=res.data.total;
            this.size=res.data.size;
            this.current=res.data.current;
            this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    };

    @action getObject= ()=>{
        let _this=this;
        return getJSON({
            urlKey:'gzyObjectList',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                // customFlg: 0
            },
        }).then((resp)=>{
            _this.objectList=resp.data;
        },(resp)=>{

        })
    };

    @action deleteContractTemplate=(id)=>{
        return getJSON({
            urlKey:'deleteContractTemplate',
            method:'delete',
            encodeData:{
                id
            }
        })
    }
}

const templateListStore = new TemplateListStore();
export default templateListStore;
export {templateListStore}