import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button,Modal,message} from "antd";
import {observer} from 'mobx-react';
import {Utils} from '../../../utils';

class Contents extends React.Component{
    render(){
        const {data,type,
            url}=this.props;
        return url?(<div className={'buy-cotent'}>
                <div className={'title'}>{type=='BALANCE'?data.tenantPck.pckName:data.name}</div>
                <p className={'fz-12'}>￥<span className={'money'}>{Utils.formatCurrency(Utils.getDecimalNum(type=='BALANCE'?data.tenantPck.payAmount/100:data.reducePrice/100))}</span>元</p>
                <img src={url} className={'img'}/>
                <p className={'text-gray'}>扫码微信支付</p>
            </div>):(<div className="flex" style={{height:200,justifyContent:'center'}}>正在加载中。。。请稍候。。。</div>)
    }
}

@observer
class Account extends Component{
    store=this.props.store;
    getpkgs=(hasbtn)=>{
        let {pkgs}=this.store;
        return pkgs.map((il,i)=>{
            return (
                <div className={`pkg-card m-b-10 ${i==(pkgs.length-1)?'':'m-r-10'}`} key={il.id}>
                    <div className={'block pkg-info'}>
                        <h3>{il.name}</h3>
                        <p className={'info-des'}>{il.remark}</p>
                        <p className={'fz-12 m-t-10'}>￥<span className={'money'}>{Utils.formatCurrency(Utils.getDecimalNum(il.reducePrice/100))}</span>元</p>
                        {hasbtn?
                            <div className={'buy-btn'}>
                                <Button type={'primary'} style={{width:120}} onClick={()=>this.showBuy(il,'WX')} className={'bg-green'}>立即购买</Button>
                            </div>
                            :null}
                    </div>
                    {il.details.map((el,e)=>{
                        return <div className={'block pkg-detail'} key={el.id}>
                            <p>套餐产品：{el.product.name}</p>
                            <p className={'text-gray'}>标准价格：<span className={'delete'}>{Utils.formatCurrency(Utils.getDecimalNum(el.product.price/100))}元/{el.product.unit}</span></p>
                            <p>套餐价格：<span className={'pkg-price'}>{Utils.formatCurrency(Utils.getDecimalNum(el.price/100))}</span>元/{el.product.unit}</p>
                        </div>
                    })}

                </div>
            )
        })
    }
    showBuy=(data,type)=>{
        let _this=this;


        this.Modal=Modal.confirm({
            title:type=='WX'?'立即购买':'立即续费',
            centered:true,
            className:'buy-modal',
            maskClosable:false,
            // icon:<LegacyIcon type={''} />,
            okText:'支付完成',
            cancelText:'残忍退出',
            content:<Contents data={data} type={type} url={''}/>,
            okButtonProps:{
                type:'primary',
                size:'small',
                children:'支付完成',
            },
            cancelButtonProps:{
                type:'primary',
                size:'small',
                ghost:true,
                children:'残忍退出',
            },
            onCancel:()=>{
                _this.store.getAccount();
                _this.store.getPackage();
                _this.closeModal();
            },
            onOk:()=>{
                _this.store.getAccount();
                _this.store.getPackage();
                _this.closeModal();
            }

        });
        this.store.showBuy(data,type).then((res)=>{
            if(res.code==0){
                _this.Modal.update({
                    content:<Contents data={data} type={type} url={res.data.url}/>
                })
            }
        });
    }
    goMyAccount=()=>{
        const {root,history}=this.props;
        history.push({pathname:`${root}/sign/bill`,state:{businessType:this.store.activeBusiness}})
    }
    closeModal=()=>{
        this.Modal.destroy();
    }
    changePck=()=>{
        let info=Modal.info({
            title:'温馨提示',
            content:'变更套餐请联系客服：400-052-1169',
            onOk:()=>{
                info.destroy();
            }
        })
    }
    render(){
        const {icon} =this.props;
        const {account}=this.store;

        return (
            // <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
            //     <HeaderContent history={this.props.history} text={this.props.text}/>
                <div>
                    <div className={'account-info'}>
                        <div className={'account-title'}>

                            <div className={'left-info'}>
                                <img src={icon} className={'icon'} />
                                {/*<Icon type="money-collect" color={'#48A8F1'} size={32} className={'icon'}/>*/}
                                <p>账户余额 <span className={'money'}>{Utils.formatCurrency(Utils.getDecimalNum(account.balance?account.balance/100:0))}</span>元</p>
                                {account.tenantPck?
                                    <p className={'pkg-info'}>
                                        <span className={'pkg-name'}>{account.tenantPck.pckName}</span>
                                        <span className={'pkg-detail'}>(续费价格：￥{Utils.formatCurrency(Utils.getDecimalNum(account.tenantPck.payAmount/100))})</span>
                                    </p>:null
                                }
                            </div>

                            {account.tenantPck?<div className={'btn-right'}>
                                <Button type={'default'}  onClick={this.changePck}>变更套餐</Button>
                                <Button type={'primary'} className={'bg-green'} onClick={()=>this.showBuy(account,'BALANCE')}>套餐续费</Button>
                                <Button type={'primary'} onClick={this.goMyAccount}>我的账单</Button>
                            </div>:null}
                        </div>
                    </div>
                    <div className={'more-pkgs'}>
                        <h3 className={'border-tit'} style={{borderBottom:'none'}}>更多套餐</h3>
                        <div className={'pkgs-wrap'}>
                            {this.getpkgs(!account.tenantPck?true:false)}
                        </div>
                    </div>

                </div>
            // </Layout>
        )
    }
    componentDidMount(){


    }
    componentWillUnmount(){

    }
}

export default Account