
import React from 'react';
import { Skeleton } from 'antd';

class LoadingContent extends React.Component {
    render() {
        return (
            <Skeleton style={{width:'600 !important',height:'100vh !important','background':'white'}} loading={this.props.loading}>sfvsfvsdfvsdfv</Skeleton>
        );
    }
}

export default LoadingContent;
