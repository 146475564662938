import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Button, Modal, Row, Col, message, Popconfirm, Switch } from 'antd';
import {observer, inject} from 'mobx-react';
import FieldType from './FieldType';
import FieldForm from './FieldForm';
import {Utils} from "../../modules/utils";


@inject('standObjFieldStore')
@observer
class FieldContent extends Component{

    state={
        visible:false,
        modalTitle:'新增自定义字段',
        type:'create',
    };
    store=this.props.standObjFieldStore;
    switchItem=(data)=>{
        if(!data.custom){
            return;
        }
        this.store.fieldId=data.id;
        this.store.getOrUpdateOrDeleteField('put',{enable:!data.enable,itemType:data.itemType}).then((res)=>{
            if(res.code==0){
                message.success(res.msg);
                this.store.getOrNewFieldContentData('get');
            }
        },(res)=>{
            message.error(res.msg)
        });
    };
    getColumn=()=>{
        let _this=this;
        let commondata1=[
            {
                title:'字段名称',
                dataIndex:'name',
                width:'20%',
            },
            {
                title:'API名称',
                dataIndex:'apiKey',
                width:'20%',
            },
            {
                title:'字段类型',
                dataIndex:'itemType',
                width:'20%',
                render:(text, record, index)=>{
                    let extInfo=JSON.parse(record.extInfo)||{};
                    return Utils.getItemText(text||1,extInfo.is_detail)
                }
            }
        ];
        let commondata2=[
            {
                title:'状态',
                dataIndex:'enable',
                width:'20%',
                render:(text, record, index)=>{
                    return (
                        <span style={{position:'relative'}}>
                            {record.custom?<Popconfirm title={`确定${text?'关闭':'开启'}当前字段？`}
                                        onConfirm={this.switchItem.bind(this,record)}
                                        okText="确定"
                                        cancelText="取消">
                                <span className={'bg-span'}></span>
                            </Popconfirm>:null}
                            <Switch size={'small'} disabled={!record.custom} checked={text} checkedChildren="开" unCheckedChildren="关"/>
                        </span>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'operate',
                width:'20%',
                render:(text, record, index)=>{
                    return (
                        <div>
                           <LegacyIcon type={'edit'} onClick={_this.showModal.bind(_this,{type:record.custom?'diyEdit':'sysEdit',record:record})} className={'m-r-15 text-blue'}/>
                            <Popconfirm title="你确定要删除吗?" onConfirm={_this.deleteItem.bind(_this,record)} okText="确定" cancelText="取消">
                                <LegacyIcon type={'delete'} className={'m-r-15 text-red'}/>
                            </Popconfirm>
                        </div>
                    );
                }
            },
        ];
        // let dataDiy=[{
        //     title:'引用对象',
        //     dataIndex:'from_obj',
        // },
        //     {
        //         title:'引用字段名',
        //         dataIndex:'from_obj_name',
        //     },
        //     {
        //         title:'引用字段类型',
        //         dataIndex:'from_obj_type',
        //     },
        // ];

        return commondata1.concat(commondata2)

    };

    showModal = (data) => {
        this.setState({
            visible: true,
            type:data.type,
            modalTitle:data.type=='create'?'新增自定义字段':data.type=='diyEdit'?'编辑自定义字段':'编辑系统字段'
        });
        //打开编辑弹框
        if(data.type!=='create'){
            this.store.setFieldId(data.record,this.props.objectName);
        }else{
            this.store.getApiKey()
        }

    };
    deleteItem = (data)=>{
        let _this=this;
        this.store.fieldId=data.id;
        this.store.getOrUpdateOrDeleteField('delete',).then((res)=>{
            if(res.code==0){
                message.success(res.msg);
                _this.store.getOrNewFieldContentData('get');
            }
        })
    };

    OkHandler = ()=>{
        const { form } = this.formRef.props;
        const {selectType} =this.store;
        const _this=this;
        form.validateFields((err) => {
            if (err) {
                return;
            };
            let values = this.formRef.getValues();
            values=values?values:form.getFieldsValue();
            values['itemType']=selectType.value;
            values['defaultValue']=values['defaultValue']?values['defaultValue']:'';
            values['helpText']=values['helpText']?values['helpText']:'';
            values=_this.formatPostValues(values);

            console.log('Received values of form: ', values);

            if(_this.state.type ==='create'){
                _this.store.getOrNewFieldContentData('post',values).then((res)=>{
                    if(res.code==0){
                        message.success(res.msg);
                        _this.hideModal();
                        _this.store.getOrNewFieldContentData('get');
                    }else{
                        message.error(res.msg||'服务端错误')
                    }
                });
            }else{
                _this.store.getOrUpdateOrDeleteField('put',values).then((res)=>{
                    if(res.code==0){
                        message.success(res.msg);
                        _this.hideModal();
                        _this.store.getOrNewFieldContentData('get');
                    }else{
                        message.error(res.msg||'服务端错误')
                    }
                });
            }

        });
    };

    formatPostValues=(values)=>{
        const {selectType} =this.store;
        let info={};
        let options=[];
        switch (selectType['type']){
            case 'Text':
                info={
                    min:values['min'],
                    max:values['max']
                };
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                delete  values.min;
                delete  values.max;
                return values;
            case 'Textarea':
                info={
                    min:values['min'],
                    max:values['max']
                };
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                delete  values.min;
                delete  values.max;
                return values;
            case 'Bool':
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                return values;
            case 'Date':
                info={
                    date_type:values['date_type']
                };
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                delete  values.date_type;
                return values;
            case 'AutoNum':
                info={
                    auto_template:values['auto_template'],
                    auto_index:values['auto_index'],
                };
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                delete  values.auto_template;
                delete  values.auto_index;
                return values;
            case 'Integer':
                info={
                    max:values['max']
                };
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                delete  values.max;
                return values;
            case 'Real':
                info={
                    decimal:values['decimal'],
                    max:values['max']
                };
                values['label']=values['name'];
                values.extInfo=JSON.stringify(info);
                delete  values.decimal;
                delete  values.max;
                return values;
            case 'Related':
                info={
                    is_detail:false
                };
                values.extInfo=JSON.stringify(info);
                return values;
            case 'Detail':
                info={
                    is_detail:true
                };
                values.extInfo=JSON.stringify(info);
                return values;
            case 'SingleSelect':
                values['label']=values['name'];
                options=this.store.operateField.pickoptions;
                options=options.map((item,i)=>{item.optionOrder=i+1;return item});
                values.pickoptions=options||[];
                info={};
                values.extInfo=JSON.stringify(info);
                delete values.pickOptions;
                return values;
            case 'MultiSelect':
                values['label']=values['name'];
                options=this.store.operateField.pickoptions;
                options=options.map((item,i)=>{item.optionOrder=i+1;return item});
                values.pickoptions=options||[];
                info={};
                values.extInfo=JSON.stringify(info);
                delete values.pickOptions;
                return values;
            default:
                console.log(values);

        }
    };

    hideModal = () => {
        const { form } = this.formRef.props;
        form.resetFields();
        this.store.setObservState(true);
        this.setState({
            visible: false,
        });
    };

    getTitle=()=>{
        return (
            <div className={'flex space-between'}>
                <h3>自定义字段</h3>
                <Button type={'link'} onClick={this.showModal.bind(this,{type:'create'})}><LegacyIcon type={'plus'} />新增自定义字段</Button>
            </div>
        );
    };

    handleFieldType=(selectType)=>{
        this.store.setType(selectType);
        if(this.store.operateField){
            this.store.operateField.defaultValue=null;
        }
        const { form } = this.formRef.props;
        form.resetFields();
    }

    render(){
        let {objectName}=this.props;
        let {types,selectType,fieldsData,operateField,apiKey,loading,btnLoading}=this.store;
        let tableData=this.departFields(fieldsData);
        return (
            <div>
                <Table className={'m-b-10'} loading={loading} columns={this.getColumn()} rowKey={'id'} dataSource={tableData.diyFields} size={'small'} bordered title={this.getTitle} pagination={false}/>
                <Table loading={loading} columns={this.getColumn()} rowKey={'id'} dataSource={tableData.sysFields} size={'small'} bordered  title={()=>(<h3>系统字段</h3>)} pagination={false}/>

                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.visible}
                    width={850}
                    onOk={this.OkHandler}
                    onCancel={this.hideModal}
                    okText="保存"
                    closeIcon={(<span className={'text-black'}>关闭</span>)}
                    okButtonProps={{ loading:btnLoading }}
                    cancelText="取消"
                >
                    <Row gutter={24}>
                        {this.state.modalTitle=='新增自定义字段'?<Col xs={8} >
                            <FieldType handleFieldType={this.handleFieldType} types={types} selectType={selectType} />
                        </Col>:''}
                        <Col xs={this.state.modalTitle=='新增自定义字段'?16:24} className={this.state.modalTitle=='新增自定义字段'?'border-left-1px':''}>
                            <FieldForm defaultapiKey={apiKey} selectType={selectType} wrappedComponentRef={formRef => {
                                this.formRef = formRef;
                            }} operateField={operateField} store={this.store} objectName={objectName}/>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
    componentWillReceiveProps(nextProps){
        const {apiSymbol,objectName} =nextProps;
        this.store.setApiSymbol(apiSymbol,objectName);
    }

    componentWillMount(){
        let _this=this;
        const {apiSymbol,objectName} =this.props;
        this.store.setApiSymbol(apiSymbol,objectName);
    }

    departFields=(fieldsData)=>{
        let diyFields=fieldsData.filter((item)=>item.custom)||[];
        let sysFields=fieldsData.filter((item)=>!item.custom)||[];
        return {
            diyFields,sysFields
        }
    };

    componentWillUnmount(){
        this.store.setObservState();
    }
}

export default FieldContent