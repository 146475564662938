import React from "react";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import fetch from "isomorphic-fetch";
import getJSON from '../../api/url.js';
import CanvasImg from './CanvasImg';
import './cropper.less';

export default class UploadImgCropper extends React.Component {

    state={
        visible:false,
        file:null,
        operateImg:null,
        showbgUpload:false
    }
    clickUpload = () => {
        if(this.input){
            this.input.value=null;
            this.input.click();
            return;
        }
        let input = document.createElement('input');
        let inputBox = document.createElement('div')
        input.setAttribute('type','file');
        input.setAttribute('accept',"image/*");
        input.onchange = (e)=>{
            this.chooseFile(e)
        };
        inputBox.style.height = 0;
        inputBox.style.overflow = 'hidden';
        inputBox.appendChild(input)
        document.body.appendChild(inputBox)
        this.input=input;
        this.input.click()
    }

    chooseFile=(e)=>{
        if(!e.target.files[0]){
            return;
        }
       let size= e.target.files[0].size;
       let type=e.target.files[0].type;
       if(type!='image/jpeg'&&type!='image/jpg'&&type!='image/png'){
           return;
       }
        let sizekb=size/1024;
        if(sizekb>5120){
            return;
        };
        debugger;
        if(this.state.visible){//弹框显示时的重新上传点击
            this.setState({
                file:e.target.files[0],
            });
        }else{
            this.setState({//未打开弹框时的上传事件
                visible:true,
                file:e.target.files[0],
            });
        }

    };
    // getImage=(file)=>{
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file); //读取文件并将文件以URL的形式保存在resulr属性中 base64格式
    //     return URL.createObjectURL(file);
    // }
    onCancel=()=>{
        this.setState({
            visible:false,
        })
    };
    onOk=()=>{
        this.setState({
            visible:false,
        })
        this.props.setImgUrl(this.state.operateImg)
    };

    passUrl=(url)=>{
        this.setState({
            operateImg:url
        })
    }

    render() {
        const {imgUrl} = this.props;
        let {img,file} =this.state;
        return (
            <div className="upload-box" style={{marginBottom:30}}>
                {!imgUrl?<div className="sealUpload" onClick={this.clickUpload}>
                    <div className="sealIcon">
                        <PlusOutlined size={15} />
                    </div>
                    <div className="sealText">点击上传印章</div>
                    <div className="sealTips">印章内容须与企业名称一致，请在<strong>白纸</strong>上盖章/签章，在此上传图片大小不超过5MB,支持.png、.jpg、.jpeg格式</div>
                </div>:
                    <div className="sealView" >
                        <img src={imgUrl?`data:image/png;base64,${imgUrl}`:''} />
                        <div className="sealViewFooter">印章图片</div>
                        <div className={'sealViewHover'}  onClick={this.clickUpload}>
                            <span><UploadOutlined /><br />重新上传</span>
                        </div>
                    </div>
                }
                <Modal
                    visible={this.state.visible}
                    title="编辑图片"
                    width={650}
                    okText="确定"
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    footer={null}
                >
                    <CanvasImg fileInput={this.input} passUrl={this.passUrl} file={file} />
                    <div className={'upload-footer-btn'}>
                        <Button type={'link'} onClick={this.clickUpload}>重新上传</Button>
                        <span>
                            <Button type={'primary'} className={'m-r-10'} onClick={this.onOk}>确定</Button>
                            <Button type={'default'} onClick={this.onCancel}>取消</Button>
                        </span>
                    </div>
                </Modal>
            </div>
        );
    }


}

function getAliOssInfo(url) {
    return getJSON({
        urlKey:url,
        method:'get'
    }).then((rst)=>{
        if(Number(rst.code)===0){
            return rst
        }else{
            return false
        }
    });
}
