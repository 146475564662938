import React,{Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio, Input, Switch, InputNumber, Table, Select } from 'antd';
const formItemLayout={
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
}
const options = [
    { label: '日期', value: 0 },
    { label: '日期+时间', value: 1 },
];

const {Option} =Select;


export default class TextAndDateFormContent extends Component{
    state={
        dateType:0
    };

    validMaxMin=(rule, value, callback)=>{
        const {form} =this.props;
        let {field} =rule;
        if(field=='max'){
            let min=form.getFieldValue('min');
            if(min&&value<=min){
                callback('最大值应大于最小值！')
            }
            return callback()
        }else{
            let max=form.getFieldValue('max');
            if(max&&value>=max){
                callback('最大值应大于最小值！')
            }
            return callback()
        }
    };

    getSettingBlock=()=>{
        let {selectType} =this.props;
        return this[`get${selectType.type}Block`]();
    };

    getTextBlock=()=>{
        let {operateField,form} =this.props;
        let {min,max=300} =operateField&&operateField.extInfo?JSON.parse(operateField.extInfo):{};
        let {getFieldDecorator} = form;
        return (
            <div className={'diff-field-setting-block'}>
                <Form.Item label="最大长度" {...formItemLayout}>
                    {getFieldDecorator('max', {
                        rules: [
                            {required: true, message: '请输入'},
                            {validator:this.validMaxMin.bind(this)},
                            {pattern: /^(0|[1-9][0-9]*)$/, message: '只能输入大于等于0的正整数!'}
                        ],
                        initialValue:max,
                    })(
                        <InputNumber max={300} min={0} step={1}/>
                    )}
                </Form.Item>
                <Form.Item label="最小长度" {...formItemLayout}>
                    {getFieldDecorator('min', {
                        rules: [
                            {pattern: /^(0|[1-9][0-9]*)$/, message: '只能输入大于等于0的正整数!'},
                            {validator:this.validMaxMin.bind(this)}],
                        initialValue:min,
                    })(
                        <InputNumber max={300} min={0} step={1}/>
                    )}
                </Form.Item>
                <Form.Item label="唯一性" {...formItemLayout}>
                    {getFieldDecorator('uniqued', {
                        initialValue:operateField?operateField.uniqued:false,
                        valuePropName: 'checked'
                    })(<Switch size={'small'}/>)}
                </Form.Item>
            </div>
        );
    };

    getTextareaBlock=()=>{
        let {operateField,form} =this.props;
        let {min,max=300} =operateField&&operateField.extInfo?JSON.parse(operateField.extInfo):{};
        let {getFieldDecorator} = form;
        return (
            <div className={'diff-field-setting-block'}>
                <Form.Item label="最大长度" {...formItemLayout}>
                    {getFieldDecorator('max', {
                        rules: [
                            {required: true, message: '请输入'},
                            {validator:this.validMaxMin.bind(this)},
                            {pattern: /^(0|[1-9][0-9]*)$/, message: '只能输入大于等于0的正整数!'}
                        ],
                        initialValue:max,
                    })(
                        <InputNumber max={300} min={0} step={1}/>
                    )}
                </Form.Item>
                <Form.Item label="最小长度" {...formItemLayout}>
                    {getFieldDecorator('min', {
                        rules: [
                            {pattern: /^(0|[1-9][0-9]*)$/, message: '只能输入大于等于0的正整数!'},
                            {validator:this.validMaxMin.bind(this)}],
                        initialValue:min,
                    })(
                        <InputNumber max={300} min={0} step={1}/>
                    )}
                </Form.Item>
            </div>
        );
    };

    getBoolBlock=()=>{
        return null
    };

    getDateBlock=()=>{
        const _this=this;
        let {operateField,form} =this.props;
        const {getFieldDecorator} = form;
        let {date_type=0} = operateField&&operateField.extInfo?JSON.parse(operateField.extInfo):{};
        return (
            <div className={'diff-field-setting-block'}>
                <Form.Item label="日期类型" {...formItemLayout}>
                    {getFieldDecorator('date_type', {
                        rules: [{required: true, message: '请选择'}],
                        initialValue:date_type
                    })(
                        <Radio.Group size="small" onChange={changeDateType}
                                     options={options} />
                    )}
                </Form.Item>
            </div>
        )
        function changeDateType(e){
            _this.setState({
                dateType:e.target.value
            });
        }
    };

    getIntegerBlock=()=>{
        const _this=this;
        let {operateField,form} =this.props;
        const {getFieldDecorator} = form;
        let {max=15} =operateField&&operateField.extInfo?JSON.parse(operateField.extInfo):{};
        return (
            <div className={'diff-field-setting-block'}>
                <Form.Item label="最大长度" {...formItemLayout}>
                    {getFieldDecorator('max', {
                        rules: [
                            {required: true, message: '请输入'},
                            {pattern: /^(0|[1-9][0-9]*)$/, message: '只能输入大于等于0的正整数!'}
                        ],
                        initialValue:max,
                    })(
                        <InputNumber  min={0} step={1}/>
                    )}
                </Form.Item>
                <Form.Item label="唯一性" {...formItemLayout}>
                    {getFieldDecorator('uniqued', {
                        initialValue:operateField&&operateField.uniqued?operateField.uniqued:false,
                        valuePropName: 'checked'
                    })(<Switch size={'small'}/>)}
                </Form.Item>
            </div>
        );
    };

    getRealBlock=()=>{
        const _this=this;
        let {operateField,form} =this.props;
        const {getFieldDecorator} = form;
        let {max=15,decimal=0} =operateField&&operateField.extInfo?JSON.parse(operateField.extInfo):{};
        return (
            <div className={'diff-field-setting-block'}>
                <Form.Item label="最大长度" {...formItemLayout}>
                    {getFieldDecorator('max', {
                        rules: [
                            {required: true, message: '请输入'},
                            {pattern: /^(0|[1-9][0-9]*)$/, message: '只能输入大于等于0的正整数!'}
                        ],
                        initialValue:max,
                    })(
                        <InputNumber min={0} step={1}/>
                    )}
                </Form.Item>
                <Form.Item label="小数点位数" {...formItemLayout}>
                    {getFieldDecorator('decimal', {
                        initialValue:decimal,
                    })(
                        <InputNumber max={14} min={0} step={1} />
                    )}
                </Form.Item>
            </div>
        );
    };

    getAutoNumBlock=()=>{
        let {operateField,form} =this.props;
        let {auto_template,auto_index} =operateField&&operateField.extInfo?JSON.parse(operateField.extInfo):{};
        let {getFieldDecorator} = form;
        return (
            <div className={'diff-field-setting-block'}>
                <Form.Item label="显示格式" {...formItemLayout}>
                    {getFieldDecorator('auto_template', {
                        rules: [
                            {required: true, message: '请输入'},
                        ],
                        initialValue:auto_template,
                    })(
                        <Input placeholder={'例如：SO#{YYYY}{MM}{DD}-{0000}'}/>
                    )}
                </Form.Item>
                <Form.Item label="开始编号" {...formItemLayout}>
                    {getFieldDecorator('auto_index', {
                        rules: [
                            {required:true, message: '请输入'},
                            ],
                        initialValue:auto_index,
                    })(
                        <InputNumber disabled={!!auto_template}/>
                    )}
                </Form.Item>
            </div>
        )
    };

    render(){
        let {operateField,defaultapiKey,selectType,form} =this.props;
        const {getFieldDecorator} = form;

        return (
            <div className={'field-type-content'}>
                <Form.Item label="字段名称" {...formItemLayout}>
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:operateField?operateField.label:''
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="API名称" {...formItemLayout}>
                    {getFieldDecorator('apiKey', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:defaultapiKey?defaultapiKey:operateField?operateField.apiKey:''
                    })(
                        <Input readOnly={true} />
                    )}
                </Form.Item>
                {/*{selectType.type!=='AutoNum'?*/}
                    <Form.Item label="是否必填" {...formItemLayout}>
                    {getFieldDecorator('required', {
                        rules: [{required: true, message: '请选择'}],
                        initialValue:operateField&&operateField.required?operateField.required:false,
                        valuePropName: 'checked'
                    })(
                        <Switch size={'small'} />
                    )}
                </Form.Item>
                {/* :null}*/}
                {this.getSettingBlock()}
                <Form.Item label="帮助文本" {...formItemLayout}>
                    {getFieldDecorator('helpText', {
                        rules: [{required: false, message: '请输入'}],
                        initialValue:operateField?operateField.helpText:''

                    })(
                        <Input.TextArea />
                    )}
                </Form.Item>
                {selectType.type!=='Bool'?(<Form.Item label="默认值" {...formItemLayout}>
                    {getFieldDecorator('defaultValue',{
                        rules: [{required: false, message: '请输入'}],
                        initialValue:operateField?operateField.defaultValue:''
                    })(
                        <Input.TextArea />
                    )}</Form.Item>):
                    (<Form.Item label="默认值" {...formItemLayout}>
                        {getFieldDecorator('defaultValue',{
                            rules: [{required: false, message: '请输入'}],
                            initialValue:operateField?operateField.defaultValue:''
                        })(
                            <Select>
                                <Option value={'1'}>选中</Option>
                                <Option value={'0'}>不选中</Option>
                            </Select>
                        )}
                </Form.Item>)}
                {selectType.type==='AutoNum'?(
                    <table className={'autonum-table'}>
                        <thead>
                        <tr>
                            <td colSpan={6} className={'text-black'}>显示格式中的替换变量说明：</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{0}</td>
                            <td style={{width:60}}>必需</td>
                            <td colSpan={3}>
                                序列编号。包括在花括号中的一个或多个零代表序列编号本身。花括号中零的个数表示将显示的 最小数字位数。如果实际编号的位数小于此数，将在其前边置零。最大位数为10位。
                            </td>
                        </tr>
                        <tr>
                            <td>{`{YY}
                                {YYYY}`}</td>
                            <td>可选</td>
                            <td colSpan={3}>
                                年份。包括在花括号中的两个或四个“Y”字符，代表记录创建日期的年份。您可以显示年份的两位（如“04”） 或全部的四位（如“2004”）。
                            </td>
                        </tr>
                        <tr>
                            <td>{`{MM}`}</td>
                            <td>可选</td>
                            <td colSpan={3}>
                                月份。包括在花括号中的两位字符表示记录创建日期的月份数字（如“01”表示一月，“02”表示二月）。
                            </td>
                        </tr>
                        <tr>
                            <td>{`{DD}`}</td>
                            <td>可选</td>
                            <td colSpan={3}>
                                日期。包括在花括号中的两位字符表示记录创建日期的日期数字（如“01”至“31”都是一月的有效日期）。
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={6} className={'text-black'}>备注 如果您修改现有字段的“显示格式”,只会影响在更新日期之后创建的记录</td>
                        </tr>
                        </tfoot>
                    </table>
                ):null}
            </div>
        )

    };

    componentWillMount(){
        let {operateField,selectType} =this.props;
        if(operateField&&selectType.type==7){
            let info=JSON.parse(operateField.extInfo);
            this.setState({
                dateType:info.date_type
            })
        }

    }
}