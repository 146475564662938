import {observable, action} from 'mobx';
import assign from 'lodash/assign';
import getJSON from '../../../api/url.js';
import {Utils} from '../../../modules/utils';


class StandObjFieldStore {
    @observable types;
    @observable apiSymbol;
    @observable apiKey;
    @observable fieldsData;
    @observable loading;

    @observable fieldId;
    @observable operateField;
    @observable selectType;
    @observable btnLoading;

    @observable objects;

    constructor() {
        this.setObservState();
    }
    @action setObservState=(onlyModalNeed)=>{
        if(!onlyModalNeed){
            this.apiSymbol = null;
            this.apiKey= '';
            this.fieldsData=[];
            this.loading=false;
        }
        this.fieldId='';
        this.operateField={};
        this.btnLoading=false;
        this.types=Utils.items;
        this.selectType=this.types[0];
        this.objects=[];
    }
    //获取全部字段，新建字段
    @action getOrNewFieldContentData= (resMethod,data)=>{
        let _this=this;
        this.loading=true;
        this.btnLoading=true;
        return getJSON({
            urlKey:'getAllItems',
            method:resMethod,
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                objectId: this.ApiSymbol
            },
            data
        }).then((res)=>{
            if(!data){
                _this.fieldsData=res.data;
                _this.loading=false;
            }
            _this.btnLoading=false;
            _this.loading=false;
            return res;
        },(res)=>{
            _this.btnLoading=false;
            _this.loading=false;
            return res;
        })
    };

    @action getApiKey= ()=>{
        let _this=this;
        return getJSON({
            urlKey:'newItemApiKey',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                objectId: this.ApiSymbol
            },
        }).then((res)=>{
            _this.apiKey=res.data;
        })
    }

    //根据字段ID获取字段全部信息,更新,删除字段
    @action getOrUpdateOrDeleteField= (resMethod,data)=>{
        let _this=this;
        if(!this.fieldId){
            return;
        }
        this.btnLoading=true;
        return getJSON({
            urlKey:'getItemDetailById',
            method:resMethod,
            urlData:[this.fieldId],
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                objectId: this.ApiSymbol
            },
            data
        }).then((res)=>{
            if(resMethod=='get'&&res.code==0){
                _this.operateField=res.data;
                let select=_this.types.filter((item)=>item.value===res.data.itemType);
                _this.selectType=select[0];
            }
            this.btnLoading=false;
            return res;
        },(res)=>{
            _this.btnLoading=false;
            return res;
        })
    };

    @action setFieldId=(data)=>{
        this.fieldId=data.id;
        this.apiKey=data.apiKey;
        this.operateField=data;
        let select=this.types.filter((item)=>item.value===data.itemType);
        this.selectType=select[0];
    };

    //保存定义实体的ID和name
    @action setApiSymbol= (ApiSymbol,objectName)=>{
        this.ApiSymbol=ApiSymbol;
        // this.objectName=objectName;
        // debugger;
        // if(this.selectType.value==10){
        //     if(this.operateField){
        //         this.operateField.label=this.objectName;
        //     }else{
        //         this.operateField={label:this.objectName};
        //     }
        // }
        this.getOrNewFieldContentData('get');
    }

    @action setType=(selectType)=>{
        this.selectType=selectType;
        this.operateField={};
    }
    //获取所有实体对象
    @action getObject= ()=>{
        let _this=this;
        return getJSON({
            urlKey:'object',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                customFlg: 0
            },
        }).then((resp)=>{
            _this.objects=resp.data;
        },(res)=>{

        })
    }

    @action updateParantState= (pickoptions)=>{
        // debugger;
        if(this.operateField){
            this.operateField={...this.operateField,pickoptions:pickoptions}
        }else{
            this.operateField={
                pickoptions:pickoptions
            }
        }
    }
}

const standObjFieldStore = new StandObjFieldStore();//通过new 创建一个homeStore对象实例通过export导出
export default standObjFieldStore;
export {standObjFieldStore}