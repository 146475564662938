import React,{Component} from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Switch, Select, Row, Col, Tooltip } from 'antd';
import {inject, observer} from "mobx-react";
const formItemLayout={
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
}
const Option=Select.Option;
@observer
class RelateTypeFormContent extends Component{
    state={

    };
    store=this.props.store;

    changeObject=(value, option)=>{
        if(this.store.operateField){
            this.store.operateField.name=option.props.children;
        }else{
            this.store.operateField={
                name:option.props.children,
            }
        }
    };

    render(){
        let {operateField,defaultapiKey,selectType,form,objectName} =this.props;
        const {getFieldDecorator} = form;
        const {objects} = this.store;
        objectName=operateField&&operateField.label?operateField.label:
            objectName?objectName:'';

        return (
            <div className={'field-type-content'}>
                <Form.Item label="业务对象名称" {...formItemLayout}>
                    {getFieldDecorator('referObjectId', {
                        rules: [{required: true, message: '请选择'}],
                        initialValue:operateField?operateField.referObjectId:''
                    })(
                        <Select onChange={this.changeObject.bind(this)}>
                            {objects.map((item,i)=>{
                                return <Option value={item.objectId} key={item.id}>{item.label}</Option>
                            })}

                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="字段名称" {...formItemLayout}>
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:operateField?operateField.name:''
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="API名称" {...formItemLayout}>
                    {getFieldDecorator('apiKey', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:defaultapiKey?defaultapiKey:operateField?operateField.apiKey:''
                    })(
                        <Input readOnly={true} />
                    )}
                </Form.Item>
                <Form.Item label="相关列表名称" {...formItemLayout}>
                    {getFieldDecorator('label', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:objectName
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="级联删除" {...formItemLayout}>
                        <Select>
                            <Option value={'1'}>提示不许删除</Option>
                            <Option value={'2'}>清理</Option>
                        </Select>
                </Form.Item>
                {/*<Form.Item label="是否必填" {...formItemLayout}>*/}
                    {/*{getFieldDecorator('required', {*/}
                        {/*rules: [{required: true, message: '请选择'}],*/}
                        {/*initialValue:operateField?operateField.required:false,*/}
                        {/*valuePropName: 'checked'*/}
                    {/*})(*/}
                        {/*<Switch size={'small'} />*/}
                    {/*)}*/}
                {/*</Form.Item>*/}
                <Row gutter={24}>
                    <Col xs={6} className={'text-right ant-form-item-label no-pd'}><label>访问控制规则</label></Col>
                    <Col xs={18} className={'ant-form-item-control-wrapper'}>
                        <div className={'ant-form-item-control'}>
                            {selectType['type']=='Detail'?'受父对象控制':'自身控制'}
                            <Tooltip placement="right"
                                     title={selectType['type']!='Detail'? '自身控制为按照本实体的权限设定规则进行访问控制，与关联关系所关联的实体无关。':
                                         '受父对象控制为按照主子明细所关联的父对象的权限规则进行访问控制，如果有多个主子明细父对象，取两个父对象权限的交集。'}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <Form.Item label="帮助文本" {...formItemLayout}>
                    {getFieldDecorator('helpText', {
                        rules: [{required: false, message: '请输入'}],
                        initialValue:operateField?operateField.helpText:''

                    })(
                        <Input.TextArea />
                    )}
                </Form.Item>
                <Form.Item label="默认值" {...formItemLayout}>
                    {getFieldDecorator('defaultValue',{
                        rules: [{required: false, message: '请输入'}],
                        initialValue:operateField?operateField.defaultValue:''
                    })(
                        <Input.TextArea />
                    )}
                </Form.Item>
            </div>
        );

    }

    componentWillMount(){
        this.store.getObject();
    }
}
export default RelateTypeFormContent