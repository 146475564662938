import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Table, Button, Popconfirm, message, Select, Row, Col, Input } from "antd";
import {observer, inject} from 'mobx-react';
import moment from 'moment';
import HeaderContent from '../../../../modules/layouts/headerContent';

const Option = Select.Option;
const {Content} = Layout;

@inject('stampListStore')
@observer
class Stamps extends Component{

    store=this.props.stampListStore
    goEdit=(item)=>{
        const {root}=this.props;
        let pathname='/admins/sign/addOrEditStamp';
        if(root=="/modules"){
            pathname=root+'/sign/addOrEditStamp'
        }
        this.props.history.push({pathname:pathname,state:{
            type:'edit',
            signatureName:item.signatureName,
            id:item.id,
            signatureImgUrl:item.signatureImgUrl
        }})
    };
    goAdd=()=>{
        const {root}=this.props;
        let pathname='/admins/sign/addOrEditStamp';
        if(root=="/modules"){
            pathname=root+'/sign/addOrEditStamp'
        }
        this.props.history.push({pathname:pathname,state:{type:'create'}})
    };
    goDetailInfo=(item)=>{
        const {root}=this.props;
        let pathname='/admins/sign/stampinfo';
        if(root=="/modules"){
            pathname=root+'/sign/stampinfo'
        }
        this.props.history.push({pathname:pathname,state:{
            id:item.id,
                signatureImgUrl:item.signatureImgUrl,signatureName:item.signatureName}})
    }

    goStampsDetail=()=>{
        const {root}=this.props;
        this.props.history.push(root+'/sign/stampdetail');
    };

    deleteItem=(id)=>{
        let _this=this;
        this.store.deleteStamp(id).then((res)=>{
            message.success('删除成功！');
            _this.store.getStempList();
        },(res)=>{
            message.error(res.msg)
        })
    };

    setDefaultSignature=(id)=>{
        let _this=this;
        this.store.setDefaultSignature(id).then((res)=>{
            message.success('设置成功！');
            _this.store.getStempList();
        },(res)=>{
            debugger;
            message.error(res.msg)
        })
    };

    getColumn=()=> {
        let _this=this;
        let columns=[
            {
                title:'印章名称',
                dataIndex:'signatureName',
                width:'20%',
            },
            {
                title:'印章创建人',
                dataIndex:'createdBy',
                width:'20%',
            },
            {
                title:'创建时间',
                dataIndex:'createdAt',
                render:time => time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-',
            },
            {
                title:'操作',
                dataIndex:'operate',
                render:(text, record, index)=>{
                    return (
                        <div>
                            <Button className={'text-black m-r-10'} type={'link'} size={'small'} icon={<LegacyIcon type={'eye'} />} onClick={this.goDetailInfo.bind(this,record)}>查看</Button>
                            {record.isDefault?<Button className={'text-black'} type={'link'} size={'small'}><LegacyIcon type={'star'} theme={'filled'}/>默认章</Button>:(
                                <span>
                                    <Button className={'text-black m-r-10'} type={'link'} size={'small'} icon={<LegacyIcon type={'edit'} />} onClick={_this.goEdit.bind(_this,record)}>编辑</Button>
                                    <Popconfirm title="你确定要删除吗?" onConfirm={_this.deleteItem.bind(_this,record.id)} okText="确定" cancelText="取消">
                                        <Button className={'text-black m-r-10'} type={'link'} size={'small'} icon={<LegacyIcon type={'delete'} />}>删除</Button>
                                    </Popconfirm>
                                    <Button className={'text-black'} type={'link'} size={'small'} icon={<LegacyIcon type={'star'} />} onClick={this.setDefaultSignature.bind(this,record.id)}>设为默认章</Button>
                                </span>
                            )}



                        </div>
                    );
                }
            },
        ];
        return columns
    };

    render(){
        const {root} =this.props;
        let {stampList,loading,current,size,total,searchHandle} =this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} text={this.props.text}/>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <div className={'pd-10 text-right'}>
                        <Button type={'primary'} size={'small'} onClick={this.goStampsDetail} className={'m-r-15'}>用印明细</Button>
                        <Button type={'primary'} size={'small'} onClick={this.goAdd}>新增印章</Button>
                    </div>
                    <Table className={'m-b-10'} loading={loading}
                           columns={this.getColumn()} rowKey={'id'} dataSource={stampList} size={'small'}
                           total={total}
                           onChange={(pagination, filters, sorter)=>{
                               searchHandle(pagination);
                           }}
                           pagination={{
                               showSizeChanger:true,
                               showQuickJumper:true,
                               defaultCurrent:1,
                               current:current,
                               pageSize:size,
                               total:total,
                           }} />
                </Content>
            </Layout>
        )
    }

    componentDidMount(){
        console.log('params======',this.props.location.search);
        const {root} =this.props;
        let search=this.props.location.search;
        if(!search){
            this.store.getStempList();
            return;
        }
        const paramsString = search.substring(1);
        const searchParams = new URLSearchParams(paramsString);
        if(root==="/modules"){
            const token = searchParams.get('token');
            const tenantId = searchParams.get('tenantId');
            window.sessionStorage.setItem("token",token);
            window.sessionStorage.setItem("tenantId",tenantId);
        }
        this.store.getStempList();
    }
    componentWillUnmount(){
        this.store.setInitState();
    }
}

export default Stamps
