import React,{ Component } from 'react';
import {Layout,Tabs} from "antd";
import {observer, inject} from 'mobx-react';
import { StickyContainer, Sticky } from 'react-sticky';

import HeaderContent from '../../../../modules/layouts/headerContent';
import iconAccunt from '../../../../imgs/account.png';
import iconInvoice from '../../../../imgs/invoiceAccount.png';
import './account.less'
import Account from "./account";

const {Content} = Layout;
const TabPane = Tabs.TabPane;

@inject('accountStore')
@observer
class AccountTabWrap extends Component{
    store=this.props.accountStore

    render(){
        const {root} =this.props;
        const {changeBusinessTab}=this.store;

        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} text={this.props.text}/>
                <Content className="right-content account-wrap">

                        <Tabs defaultActiveKey="ESIGN"  onChange={changeBusinessTab} className={'business-tab-wrap'}>
                            <TabPane tab="电子签约账户" key="ESIGN" >
                                <Account store={this.store} icon={iconAccunt} root={this.props.root} history={this.props.history}/>
                            </TabPane>
                            <TabPane tab="开票查询账户" key="EINVOICE" >
                                <Account store={this.store} icon={iconInvoice} root={this.props.root} history={this.props.history} />
                            </TabPane>
                            {/*<TabPane tab="短信" key="SMS" style={{ height: 200 }}>*/}
                            {/*    <Account store={this.store} />*/}
                            {/*</TabPane>*/}
                        </Tabs>

                </Content>
            </Layout>
        )
    }

    componentDidMount(){
        console.log('params======',this.props.location.search);
        const {root} =this.props;
        let search=this.props.location.search;
        if(!search){
            this.store.getAccount();
            this.store.getPackage();
            return;
        }
        const paramsString = search.substring(1);
        const searchParams = new URLSearchParams(paramsString);
        // const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0aGlzIGlzIHJlbnRhbCB0b2tlbiIsImF1ZCI6IlJFTlRBTC1BUFAiLCJpc3MiOiJSRU5UQUwiLCJleHAiOjE1ODQzMjUwMzUsInVzZXJJZCI6IjE0OTc5Y2UyZGExZjM5ZTQyZGE0NTk0N2FlZjViZjRlIiwiaWF0IjoxNTg0MDY1ODM1fQ.QNJ_JOqyqi3FSJcuCLzDzTpytVtY4Kv6w7lfFIguDpo";
        // const tenantId = '10046';
        // const userId='c4ca4238a0b923820dcc509a6f75849b';
        // const userName='admins-shdy';
        // this.store.setUser(userId,userName);
        // window.sessionStorage.setItem("token",token);
        // window.sessionStorage.setItem("tenantId",tenantId);
        if(root==="/modules"){
            const token = searchParams.get('token');
            const tenantId = searchParams.get('tenantId');
            const userId=searchParams.get('userId');
            const userName=searchParams.get('userName');
            this.store.setUser(userId,userName);
            window.sessionStorage.setItem("token",token);
            window.sessionStorage.setItem("tenantId",tenantId);
        }
        this.store.getAccount();
        this.store.getPackage();

    }
    componentWillUnmount(){
        this.store.setInitState();
    }
}
export default AccountTabWrap