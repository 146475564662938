import React,{Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Switch, Radio } from 'antd';
import MovableList from '../movableList/MovableList';
import {inject, observer} from "mobx-react";
const formItemLayout={
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
};
@observer
class SingleAndMultiFormContent extends Component{
    store=this.props.store;
    updateParantState=(data)=>{
        if(this.store.operateField){
            this.store.operateField.optionPickers=data
        }else{
            this.store.operateField={
                optionPickers:data
            }
        }
    };

    render(){
        let {operateField,defaultapiKey,form} =this.props;
        const {getFieldDecorator} = form;
        return (
            <div className={'field-type-content'}>
                <Form.Item label="字段名称" {...formItemLayout}>
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:operateField?operateField.label:''
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="API名称" {...formItemLayout}>
                    {getFieldDecorator('apiKey', {
                        rules: [{required: true, message: '请输入'}],
                        initialValue:defaultapiKey?defaultapiKey:operateField?operateField.apiKey:''
                    })(
                        <Input readOnly={true} />
                    )}
                </Form.Item>
                {/*<Form.Item label="是否必填" {...formItemLayout}>*/}
                    {/*{getFieldDecorator('required', {*/}
                        {/*rules: [{required: true, message: '请选择'}],*/}
                        {/*initialValue:operateField?operateField.required:false,*/}
                        {/*valuePropName: 'checked'*/}
                    {/*})(*/}
                        {/*<Switch size={'small'} />*/}
                    {/*)}*/}
                {/*</Form.Item>*/}
                <Form.Item label="帮助文本" {...formItemLayout}>
                    {getFieldDecorator('helpText', {
                        rules: [{required: false, message: '请输入'}],
                        initialValue:operateField?operateField.helpText:''

                    })(
                        <Input.TextArea />
                    )}
                </Form.Item>
                <Form.Item label="选项信息" {...formItemLayout}>
                    {getFieldDecorator('pickOptions', {
                        rules: [{required: false, message: '请输入'}],
                        initialValue:'checked'
                    })(
                        <Radio checked={true}>添加选项</Radio>
                    )}
                </Form.Item>
                <MovableList objectId={this.store.ApiSymbol} data={operateField.pickoptions||[]} type={this.store.selectType.type=='SingleSelect'?'single':'multi'} updateParantState={this.store.updateParantState}/>
            </div>
        )

    };

    componentWillMount(){
        let {operateField,selectType} =this.props;
        if(operateField&&selectType.type==7){
            let info=JSON.parse(operateField.extInfo);
            this.setState({
                dateType:info.date_type
            })
        }

    }
}
export default SingleAndMultiFormContent