import {observable, action} from 'mobx';
import getJSON from '../../api/url.js';
import moment from 'moment'

class SignFilesStore{
    @observable fileList;
    @observable loading;
    @observable current;
    @observable total;
    @observable size;

    @observable objectList;
    @observable activeTab;
    @observable createByName;
    @observable dataCode;
    @observable docTitle;
    @observable docCode;
    @observable status;
    @observable signerName;

    @observable sendTimeBgn;
    @observable sendTimeEnd;
    @observable updateAtBgn;
    @observable updateAtEnd;
    @observable isclearTime;
    @observable detailData;
    @observable openId;
    @observable doSignUrl;

    constructor(){
        this.setInitState();
    }
    @action setInitState=(isreset)=>{
        this.current=1;
        this.total=0;
        this.size=20;
        this.createByName='';
        this.dataCode='';
        this.docTitle='';
        this.docCode='';
        this.status='';
        this.signerName='';

        this.sendTimeBgn=null;
        this.sendTimeEnd=null;
        this.updateAtBgn=null;
        this.updateAtEnd=null;
        if(isreset){
            return;
        }
        this.fileList=[];
        this.objectList=[];
        this.activeTab='';
        this.loading=false;
        this.detailData={};
        this.openId='';
        this.doSignUrl='';
    }
    @action searchHandle=(pageObject)=>{
        this.current=pageObject?pageObject.current:1;
        this.size=pageObject?pageObject.pageSize:20;
        let param=this.getSearchParam();
        this.getFileList(param);

    };

    @action getSearchParam=()=>{
        let {activeTab,createByName='',dataCode='',docTitle='',docCode='',status='',signerName='',size,current,sendTimeBgn,sendTimeEnd,updateAtBgn,updateAtEnd}=this;
        return {
            objectId:activeTab,
            current,
            size,
            signerName,
            createByName,dataCode,docTitle,docCode,status,
            sendTimeBgn:sendTimeBgn?sendTimeBgn.valueOf():'',
            sendTimeEnd:sendTimeEnd?sendTimeEnd.valueOf():'',
            updatedAtBgn:updateAtBgn?updateAtBgn.valueOf():'',
            updatedAtEnd:updateAtEnd?updateAtEnd.valueOf():'',
        }
    };

    @action setDate=(type,field,value)=>{
        let date=`${type}${field}`;
        this[date]=value;
    };

    @action clearSearchList=()=>{
        this.setInitState(true);
        this.getFileList(this.getSearchParam());
    };

    @action setActiveTab=(key)=>{
        this.activeTab=key;
        this.setInitState(true);
        this.getFileList()
    };

    @action getFileList=(param)=>{
        console.log(param)
        this.loading=true;
        return getJSON({
            urlKey:'contractList',
            method:'get',
            encodeData:param||{
                current:this.current,
                size:this.size,
                objectId:this.activeTab||100010
            }
        }).then((res)=>{
            this.fileList=res.data.records;
            this.total=res.data.total;
            this.size=res.data.size;
            this.current=res.data.current;
            this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    };

    @action getFileObjects= (signId)=>{
        let _this=this;
        return getJSON({
            urlKey:'getFileObjects',
            method:'get',
        }).then((resp)=>{
            _this.objectList=resp.data;
            _this.activeTab=resp.data[0].objectId;
            if(signId){
                _this.querySignedStatus(signId)
                return;
            }
            _this.getFileList();
        },(resp)=>{

        })
    };

    @action querySignedStatus=(signId)=>{
        let _this=this;
        return getJSON({
            urlKey:'querySignedStatus',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                signId
            }
        }).then(()=>{
            _this.getFileList();
        },(resp)=>{

        })
    }

    @action getSignDetail= (id)=>{
        let _this=this;
        this.openId=id;
        return getJSON({
            urlKey:'signDetail',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                objectId:this.activeTab,
                dataId:id,
            },
        }).then((resp)=>{
            _this.detailData=resp.data;
            return resp

        },(resp)=>{
            return resp
        })
    };
    @action doSign= (root)=>{
        return getJSON({
            urlKey:'doSign',
            method:'post',
            data:{
                signId: this.detailData.currentSignId,
                returnUrl: window.location.origin.replace(/\/\/\w+./, '//www.')+'/Success.html',
            },
        })
    };
    @action refuseSign=()=>{
        return getJSON({
            urlKey:'refuseSign',
            method:'post',
            data:{
                signId: this.detailData.currentSignId,
                rejectReason: "拒签"
            },
        })
    };
    @action recallSign=()=>{
        return getJSON({
            urlKey:'recallSign',
            method:'post',
            data:{
                eContractId: this.detailData.eContractId,
                cancelReason: "撤回",
                objectId:this.activeTab
            },
        })
    };
}

const signFilesStore = new SignFilesStore();
export default signFilesStore;
export {signFilesStore}
