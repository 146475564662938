import {observable, action} from 'mobx';
import getJSON from '../../api/url.js';

class MenuWrapStore {
    @observable objectList;
    @observable loading;
    @observable selectObj;


    constructor(){
        this.setInitState();
    }

    @action setInitState=()=> {
        this.objectList = [];
        this.loading = false;
        this.selectObj = null;
    }

    @action getObject= ()=>{
        let _this=this;
        this.loading=true;
        return getJSON({
            urlKey:'object',
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                customFlg: 0
            },
        }).then((res)=>{
            _this.loading=false;
            if(res.code==0&&res.data.length>0){

                _this.objectList=res.data;
                _this.selectObj={
                    objectName:res.data[0].name,
                    apiSymbol:res.data[0].objectId,
                    objectId:res.data[0].objectId
                }
            }
        })
    }

    @action clickMenuHandle= ({ item, key, keyPath, domEvent })=>{
        console.log(item, key, keyPath, domEvent);
        this.selectObj={
            objectName:item.props.text,
            apiSymbol:key,
            objectId:key
        }
    }
}

const menuWrapStore = new MenuWrapStore();//通过new 创建一个homeStore对象实例通过export导出
export default menuWrapStore;
export {menuWrapStore}