import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Spin, message, Modal, Layout, Button } from 'antd';
import UploadOss from '../../../../components/uploadOss/upload';
import HeaderContent from '../../../../modules/layouts/headerContent';
import UploadImgCropper from '../../../../components/uploadImgCropper';
import {inject, observer} from "mobx-react";

const { Content} = Layout;
const formItemLayout={
    labelCol: { span: 5 },
    wrapperCol: { span: 14 }
};


@inject('stampOperateStore')
@observer
class AddOrEditStamp extends React.Component {
    state={
        previewVisible:false
    };

    store=this.props.stampOperateStore;

    validPhone= (rule, value, callback)=>{
        if(!(/^1[34578]\d{9}$/.test(value))){
            callback('请输入正确的手机号码！');
            return false;
        }
        callback();
    };

    validImgSrc= (rule, value, callback) => {
        let {signatureImgBase64} =this.store;
        if( !signatureImgBase64){
            callback('请选择印章图片！')
        };
        return callback();

    };

    previewImg= ()=>{
        this.setState({
            previewVisible:true,
        })
    };
    handleCancel=()=>{
        this.setState({
            previewVisible:false
        })
    };

    handleSubmit=()=>{
        const { form,root,history } = this.props;
        const {id,signatureImgBase64,type} =this.store;
        const _this=this;
        form.validateFields({ force: true },(err, values) => {
            if (err) {
                return;
            };
            let imgurl='';
            if(signatureImgBase64.indexOf(';base64,')!==-1){
               let imgdata=signatureImgBase64.split('data:image/png;base64,');
                imgurl=imgdata[1]
            }else{
                imgurl=signatureImgBase64
            }
            let data={
                signatureImgBase64:imgurl,
                signatureName:values.signatureName
            };
            this.store.addSignature(data).then((res)=>{
                message.success(type=='edit'?'印章编辑成功!':'印章添加成功！');
                history.push((root?`${root}`:'')+'/sign/stamp')
            },(res)=>{
                message.error(res.msg)
            })
        })
    };

    render() {
        const {form,root,history } = this.props;
        const { getFieldDecorator } = form;
        let { signatureImgBase64,signatureName,imgloading ,setImgUrl,type} = this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} title={`${type==='create'?this.props.text:'编辑印章'}`}>
                    {/*<Button className={'m-r-15'} type={'link'} size='small' icon={'left'} onClick={()=>{*/}
                        {/*this.props.history.goBack()*/}
                    {/*}}>返回</Button>*/}
                </HeaderContent>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label="印章名称">
                    {getFieldDecorator('signatureName', {
                        rules: [{ required: true, message: '请输入' }],
                        initialValue:signatureName
                    })(<Input />)}
                </Form.Item>
                {/*<Form.Item  {...formItemLayout} label="印章图片">*/}
                    {/*{getFieldDecorator('signatureImgUrl', {*/}
                        {/*rules: [{*/}
                            {/*required:true,*/}
                            {/*validator: this.validImgSrc,*/}
                        {/*}],*/}
                    {/*})(*/}
                        {/*<div style={{textAlign:'left',display:'flex'}}>*/}

                            {/*{ signatureImgUrl ?*/}
                                {/*<img onClick={this.previewImg.bind(this)} src={ signatureImgUrl } alt="" style={{width:100,height:100,border:'1px solid #ccc'}} />*/}
                                {/*:''}*/}

                            {/*<UploadOss*/}
                                {/*url='ossSignature'*/}
                                {/*showLoading={()=>{this.store.imgloading=true}}*/}
                                {/*type={'imgloading'}*/}
                                {/*canChoose={!imgloading}*/}
                                {/*uploadEnd={(data) => {*/}
                                    {/*setImgUrl(data);*/}
                                {/*}}*/}
                                {/*upload={(data, file, load) => {*/}
                                    {/*let reg = /\.jpg$|\.jpeg$|\.png$/i;*/}
                                    {/*if(!file.name.match(reg)){*/}
                                        {/*message.warn("上传的文件格式不正验，请重新选择!");*/}
                                        {/*return;*/}
                                    {/*}*/}
                                    {/*data=JSON.parse(data.data);*/}
                                    {/*load(Object.assign({}, data, {file}))*/}
                                {/*}}*/}
                            {/*>*/}
                                {/*<div className={'plus-img'} >*/}
                                    {/*{*/}
                                        {/*imgloading? <Spin size={'large'} spinning={imgloading} />: <Icon type="plus" className={'icon-plus'}/>*/}
                                    {/*}*/}
                                {/*</div>*/}
                            {/*</UploadOss>*/}
                        {/*</div>*/}

                    {/*)}*/}
                {/*</Form.Item>*/}
                <Form.Item {...formItemLayout} label="印章图片">
                    {getFieldDecorator('signatureimg', {
                        rules: [{required:true,
                            validator: this.validImgSrc}],
                    })(
                        <UploadImgCropper setImgUrl={setImgUrl} imgUrl={signatureImgBase64}/>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label=" ">
                    {getFieldDecorator('name')(<div>
                        <Button type={'primary'} size={'small'} onClick={this.handleSubmit} className={'m-r-15'}>提交</Button>
                        <Button type={'default'} size={'small'} onClick={()=>history.goBack()}>取消</Button>
                    </div>)}
                </Form.Item>
                {/*<Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel} centered={true} >*/}
                    {/*<img alt="印章图片" style={{ width: '100%' ,height:'100%'}} src={signatureImgUrl} />*/}
                {/*</Modal>*/}
            </Form>
                </Content>
            </Layout>
        );
    }

    componentWillMount(){
        const {location,history} =this.props;
        if(!location.state||(location.state.type==='edit'&&!location.state.id)){
            message.error('缺少参数，不能跳转！');
            history.goBack();
            return;
        }
        this.store.setType(location.state)
    }

    componentWillUnmount(){
        this.store.setInitState();
    }
}

export default Form.create({ name: 'form_in_modal' })(AddOrEditStamp)