/**
 * axios 请求发送及错误处理
 **/
import axios from 'axios';
import {message} from 'antd';
import assign from 'lodash/assign';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const fetch = function (option) {
    let {method = 'get', data, url} = option,
        Token = window.sessionStorage.getItem('token'),
        defaultConfig = {
            headers: {
                'X-Rental-Token': Token || '',
                'tenantId':window.sessionStorage.getItem('tenantId')
            }
        };
    if (option.isFormData) {
        defaultConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    }
    let reqMethod = method.toLowerCase();
    if (data) {
        return axios[reqMethod](url, data, defaultConfig);
    }
    return axios[reqMethod](url, defaultConfig);
};
/**
 * code ：0 正常， 401 token过期 ，-9999 未知错误 ，其他验证或参数错误
 **/
const response = {
    "code": 0,
    "data": false,
    "msg": "string"
};


/**
 * 检查http的status状态
 **/
const statusHandle = function (resp) {
    const {status, data, msg, code} = resp;
    //成功 需要读具体的业务逻辑
    if (status >= 200 && status < 300) {
        return Promise.resolve(assign({}, response, data));
    } else if (status === 401) {
        debugger;
        return Promise.reject(assign({}, response, {msg: '当前用户没有权限！', code, status}));
    } else if (status === 402) {
        return Promise.reject(assign({}, response, {msg: '当前数据禁止访问！', code, status}));
    } else if (status === 404) {
        return Promise.reject(assign({}, response, {msg: '未找到与当前条件匹配的数据！', code, status}));
    } else if (status === 500) {
        return Promise.reject(assign({}, response, {msg: '服务端错误！', code, status}));
    }
    //未知错误
    return Promise.reject(assign({}, response, {msg, code, status}));
};
/**
 * 检查用户是否过期
 **/
const checkAuth = function (resp) {
    const {code, msg} = resp;
    if(code === 401){
        window.sessionStorage.setItem('token', '');
        message.info(msg, 2.5, function(){
            window.location.assign('/web/login');
        });
        return Promise.reject(resp);
    }
    return Promise.resolve(resp);
};
/**
 * 业务失败处理函数
 **/
const bussinessFail = function (resp) {
    const {code, msg} = resp;
    if (code === 0 || code === 100023) {
        return Promise.resolve(resp);
    }
    return Promise.reject(assign({}, resp, {msg, code: -9999}));
};
/**
 * 成功的回调函数
 **/
const successHandle = function (resp) {
    return Promise.resolve(resp);
};


export default function request(option) {
    const Token = window.sessionStorage.getItem('token');
    if (window.location.pathname.indexOf('login') === -1) {
        if (!Token) {
            window.location.assign('./web/login');
            return false;
        }
    }
    return fetch(option)
        .then(statusHandle)
        .then(checkAuth)
        .then(bussinessFail)
        .then(successHandle)
        .catch(bussinessFail);
}
