import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Table, Button, message, Row, Col, Input } from "antd";
import {observer, inject} from 'mobx-react';
import moment from 'moment';
import HeaderContent from '../../../../modules/layouts/headerContent';
import GzyDateRangePicker from "../../../../components/gzyDateRangePicker";
import withTrim from "../../../../highGradeComps/trimwrap";
const TrimeInput=withTrim(Input);
const {Content} = Layout;

@inject('stampListStore')
@observer
class Stamps extends Component{

    store=this.props.stampListStore

    getColumn=()=> {
        let _this=this;
        let columns=[
            {
                title:'序号',
                dataIndex:'id',
                width:'20%',
            },
            // {
            //     title:'企业名称',
            //     dataIndex:'templateName',
            //     width:'20%',
            // },
            {
                title:'印章名称',
                dataIndex:'signatureName',
                width:'20%',
                render:(text, record, index)=>{
                    return text
                }
            },
            {
                title:'签署人',
                dataIndex:'signAccountName',
                width:'20%',
                render:(text, record, index)=>{
                    return text
                }
            },
            {
                title:'签署时间',
                dataIndex:'signTime',
                width:'20%',
                render:time => time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-',
            },
            {
                title:'签署文件',
                width:'20%',
                dataIndex:'docTitle',

            },

        ];
        return columns
    };
    searchHandle=(type,val)=>{
        this.store[type]=val;
    }
    changeUpdate=(field,value)=>{
        this.store.setDate('signTime',field,value)
    };
    searchBlock= ()=>{
        let {signatureName='',signTimeBgn,signTimeEnd,clearSearchList,searchHandleDetail} =this.store;
        return (<div>
                <Row gutter={8} style={{marginBottom:10}} justify={'space-between'}>
                    <Col xs={6} style={{display:'flex'}}>
                        <span>印章名称：</span>
                        <TrimeInput style={{width:'75%'}} placeholder={'请输入印章名称'} value={signatureName||undefined} onChange={(val)=>{this.searchHandle('signatureName',val.target.value)}} />
                    </Col>
                    <Col xs={8} style={{display:'flex'}}>
                        <span>用印时间：</span>
                        <GzyDateRangePicker Start={signTimeBgn} End={signTimeEnd} isclearTime showTime={true} rangeChange={this.changeUpdate}/>
                    </Col>

                    <Col xs={10} className="" >
                        <Button type="default" onClick={clearSearchList}>重置</Button>
                        <Button type="primary" onClick={()=>{searchHandleDetail(null)}} style={{marginLeft:20}}>查询</Button>
                    </Col>
                </Row>

            </div>

        )
    }

    render(){
        const {root} =this.props;
        let {stampList=[],loading,current,size,total,searchHandleDetail} =this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} text={this.props.text}>
                    <Button type={'link'} size={'small'} icon={<LegacyIcon type={'arrow-left'} />} style={{float:'right',marginTop:10,marginRight:30}} onClick={()=>{
                        this.props.history.goBack()
                    }}>返回</Button>
                </HeaderContent>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>

                    {this.searchBlock()}
                    <Table className={'m-b-10'} loading={loading}
                           columns={this.getColumn()} rowKey={'id'} dataSource={stampList} size={'small'}
                           total={total}
                           onChange={(pagination, filters, sorter)=>{
                               searchHandleDetail(pagination);
                           }}
                           pagination={{
                               showSizeChanger:true,
                               showQuickJumper:true,
                               defaultCurrent:1,
                               current:current,
                               pageSize:size,
                               total:total,
                           }} />
                </Content>
            </Layout>
        );
    }

    componentDidMount(){
        this.store.getSignatureUsedRecord();
    }
    componentWillUnmount(){
        this.store.setInitState();
    }
}

export default Stamps
