import loginStore from './loginStore';
import menuWrapStore from './objectStore/menuWrapStore';
import standObjFieldStore from './objectStore/fieldStore/standObjFieldStore';
import tenantListStore from './tenantStore/tenantListStore';

import addOrEditTplStore from './templateStore/addOrEditTplStore';
import templateListStore from './templateStore/templateListStore';
import stampListStore from './stampStore/stampListStore';
import stampOperateStore from './stampStore/stampOperateStore';
import signFilesStore from './fileStore/signFilesStore';

import accountStore from './accountStore/accountStore';
import accountBillStore from './accountStore/accountBillStore';


import departIndexStore from './userPermissionStore/departStore/departIndexStore';

export default {
    loginStore,
    menuWrapStore,
    standObjFieldStore,
    tenantListStore,

    addOrEditTplStore,
    templateListStore,
    stampListStore,
    stampOperateStore,
    signFilesStore,

    accountStore,
    accountBillStore,

    departIndexStore,


}