import request from './request.js';
import {Utils} from '../modules/utils';

const URLS={
// /system/v1/admin/login

    // "login":"/esign/v1/user/login",
    "logout":"/system/v1/admin/logout",
    "ossSignature":"/uc/v1/oss/getPolicy",
    "login":"/uc/v1/passport/login",

    'object':'/metadata/v1/object',
    "getAllItems":'/metadata/v1/item',	//获取新建某个实体的全部字段
    "newItemApiKey":'/metadata/v1/item/newItemApiKey',//新建字段生成apiKey接口
    "getItemDetailById":'/metadata/v1/item',//根据字段ID获取字段全部信息,更新,删除字段

    "addContractTemplate":"/esign/v1/contractTemplate/add",
    'ContractTemplateList':"/esign/v1/contractTemplate/listPage",
    'deleteContractTemplate':"/esign/v1/contractTemplate/delete",
    "detailContractTemplate":"/esign/v1/contractTemplate/detail",
    "updateContractTemplate":'/esign/v1/contractTemplate/update',
    "signatureList":'/esign/v1/signature/listPage',
    "deleteSignature":'/esign/v1/signature/delete',
    "signatureUsers":'/esign/v1/signatureUser/list',
    "partnersListAll":"/esign/v1/account/listAll",
    "chooseAddOwner":"/esign/v1/signatureUser/chooseAdd",
    "deleteUserSignature":"/esign/v1/signatureUser/delete",
    "setDefaultSignature":'/esign/v1/signature/setDefault',
    "addSignature":'/esign/v1/signature/add',
    "updateSignature":'/esign/v1/signature/update',
    'getDetailStampInfo':'/esign/v1/signature/detail',
    "signatureUsedRecord":"/esign/v1/signatureUsedRecord/listPage",

    'contractList':'/esign/v1/contract/listPage',
    'getFileObjects':'/esign/v1/business/objectList',
    'signDetail':'/esign/v1/contract/signDetail',
    'querySignedStatus':'/esign/v1/contract/querySignedStatus',
    'doSign':'/esign/v1/contractSign/startExtSign',
    'refuseSign':'/esign/v1/contractSign/rejectSign',
    'recallSign':'/esign/v1/contract/cancel',
    "account":"/settle/v1/pay/query/account",
    "package":"/settle/v1/pck/query/pck",
    "pkgPurchase":"/settle/v1/pay/pck/purchase",
    "costList":"/settle/v1/pck/query/consume",
    "buyList":"/settle/v1/pck/query/purchase",
    "costDetail":"/settle/v1/pck/query/consume/detail",
    "gzyObjectList":"/esign/v1/business/gzyObjectList",

    "comp":"/auth/comp",
    "compAdd":"/auth/comp/corp/add",


};

const getJSON=(option)=>{
    let {
        urlKey="login",
        data=null,
        urlData=null,
        encodeData=null,
        method="get",
    } = option,url;


    url=`${URLS[urlKey]}`;
    if(urlData&& Array.isArray(urlData)){
        urlData.forEach((il,i)=>{
            if(il){
                url+='/'+il
            }
        })
    };
    if(encodeData && typeof(encodeData)!=='string'&&typeof(encodeData)!=='number'){
        url=Utils.BuildUrl(url,encodeData)
    };
    if(data){
        return request({
            url:url,
            method:method,
            data,
            isFormData:urlKey==='login'
        })
    }else{
        return request(
            {
                url:url,
                method:method,
            }
        )
    }
};
export default getJSON;