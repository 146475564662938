import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Layout, Table, Tabs, Tag} from "antd";
import {observer, inject} from 'mobx-react';
import moment from 'moment';
import HeaderContent from '../../../../modules/layouts/headerContent';
import {Utils} from "../../../utils";
const {Content} = Layout;
const { TabPane } = Tabs;

@inject('accountBillStore')
@observer
class AccountBill extends Component{
    store=this.props.accountBillStore;
    getCostColumn=()=>{
        let {businessType} =this.store;
       let arr= [
            {
                title:'日期',
                dataIndex:'statisticTime',
                // width:'20%',
                render:(time,record,index)=>{
                    time=time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-';
                    return <span className={'text-blue cursor-pointer'} onClick={()=>this.goDetail(record)}>{time}</span>
                }
            },
            {
                title:'使用套餐',
                dataIndex:'pckName',
                // width:'20%',
            },
            {
                title:businessType=='ESIGN'?'电子签约认证（个）':'查询次数',
                dataIndex: businessType =='ESIGN'?'certificationQuantity':'quantity',
                // width:'20%',
            },
            {
                title:'电子签约合同（份）',
                dataIndex:'contractQuantity',
                // width:'20%',
            },
            {
                title:'消费金额（元）',
                dataIndex:'totalAmount',
                // width:'20%',
                render:(totalAmount)=>{
                    return Utils.getDecimalNum(totalAmount?totalAmount/100:0)
                }
            },
        ];

        businessType=='EINVOICE'&&arr.splice(3,1);
        return arr;
    }
    getBuyColumn=()=>{
        let arr= [
            {
                title:'订单编号',
                dataIndex:'id',
                // width:'20%',
            },

            {
                title:'购买日期',
                dataIndex:'createdAt',
                // width:'20%',
                render:(time,record,index)=>{
                    return time?moment(time).format('YYYY-MM-DD HH:mm:ss'):'-';

                }
            },

            {
                title:'购买套餐',
                dataIndex:'pckName',
                // width:'20%',
            },
            {
                title:'购买金额（元）',
                dataIndex:'payAmount',
                // width:'20%',
                render:(amount)=>{
                    return Utils.formatCurrency(Utils.getDecimalNum(amount?amount/100:0))
                }
            },
            {
                title:'购买账号',
                dataIndex:'userName',
                // width:'20%',
            },
            {
                title:'状态',
                dataIndex:'payStatus',
                // width:'20%',
                render:(status,record,index)=>{
                    return status==1?<Tag color={'#87d068'} >
                        已付款
                    </Tag>:<Tag color={'#f50'} >未付款</Tag>
                }
            },
        ]
        return arr;
    }
    goDetail=(record)=>{
        const {root,history}=this.props;
        history.push({pathname:`${root}/sign/billDetail`,state:{id:record.id}})
    }
    render(){

        const {root} =this.props;
        let {changeTabs,dataList,loading,current,size,total,businessType,searchHandle} =this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} title={businessType=='ESIGN'?'电子签约查询账单':'开票查询账单'}>
                    <Button size={'small'} icon={<LegacyIcon type={'arrow-left'} />} style={{float:'right',top:10,right:10}} onClick={()=>this.props.history.goBack()}>返回</Button>
                </HeaderContent>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{margin:15}}>
                    <Tabs onChange={changeTabs} type="card" defaultActiveKey={'1'}>
                        <TabPane tab="消费记录" key="1">
                            <Table className={'m-b-10'} loading={loading}
                                   columns={this.getCostColumn()} rowKey={'id'} dataSource={dataList} size={'small'}
                                   total={total}
                                   onChange={(pagination, filters, sorter)=>{
                                       searchHandle(pagination);
                                   }}
                                   pagination={{
                                       showSizeChanger:true,
                                       showQuickJumper:true,
                                       defaultCurrent:1,
                                       current:current,
                                       pageSize:size,
                                       total:total,
                                   }} />
                        </TabPane>
                        <TabPane tab="购买记录" key="2">
                            <Table className={'m-b-10'} loading={loading}
                                   columns={this.getBuyColumn()} rowKey={'id'} dataSource={dataList} size={'small'}
                                   total={total}
                                   onChange={(pagination, filters, sorter)=>{
                                       searchHandle(pagination);
                                   }}
                                   pagination={{
                                       showSizeChanger:true,
                                       showQuickJumper:true,
                                       defaultCurrent:1,
                                       current:current,
                                       pageSize:size,
                                       total:total,
                                   }} />
                        </TabPane>

                    </Tabs>

                </Content>
            </Layout>
        );
    }
    componentDidMount() {

        this.store.setBusinessType(this.props.location.state.businessType)
    }
    componentWillUnmount() {
        this.store.setInitState();
    }
}

export default AccountBill