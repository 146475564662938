import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Transfer } from 'antd';

class AddOwnerStamp extends React.Component {

    validPhone= (rule, value, callback)=>{
        if(!(/^1[34578]\d{9}$/.test(value))){
            callback('请输入正确的手机号码！');
            return false;
        }
        callback();
    };
    filterOption = (inputValue, option) => option.label.indexOf(inputValue) > -1;

    // validTransfer= (rule, value, callback)=>{
    //     let {targetKeys} =this.props;
    //     if(targetKeys.length===0){
    //         callback('请选择至少一个持有人！')
    //     }
    //     return callback();
    // };

    handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    render() {
        const { visible, onCancel, onCreate, form,data,handleTargetChange,targetKeys } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                visible={visible}
                width={640}
                title="新增持有人"
                okText="确定"
                onCancel={onCancel}
                onOk={onCreate}
            >
                <Form layout="vertical">
                    {/*<Form.Item label="持有人真实姓名">*/}
                    {/*    {getFieldDecorator('name', {*/}
                    {/*        rules: [{ required: true, message: '请输入' }],*/}
                    {/*    })(<Input />)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="持有人真实电话">*/}
                    {/*    {getFieldDecorator('number',{*/}
                    {/*        rules: [{ required: true, message: '请输入' },{*/}
                    {/*            validator:this.validPhone*/}
                    {/*        }]*/}
                    {/*    })(<Input />)}*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={"持有人"}>
                        {getFieldDecorator('items', {
                            // rules: [{validator: this.validTransfer,}],
                            // initialValue:targetKeys
                        })(
                            <Transfer
                                dataSource={data}
                                showSearch
                                filterOption={this.filterOption}
                                targetKeys={targetKeys}
                                onChange={handleTargetChange}
                                onSearch={this.handleSearch}
                                listStyle={()=>{
                                    return {width:'46.5%',height:300}
                                }}
                                render={item => {
                                    item.label=item.accountName+"("+item.accountMobile+")" +"("+(item.certStatus==1?"已认证":"未认证")+")";
                                    item.key=item.id;
                                    // item.disabled=item.certStatus!=1;
                                    return item
                                }}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default Form.create({ name: 'form_in_modal' })(AddOwnerStamp)