import React,{Component} from 'react';
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Input, InputNumber, Checkbox, Button } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';


let dragingIndex = -1;

class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);

export default class MovableList extends Component{
    state = {
        data: [
            {
                optionCode: 1,
                optionLabel: 'John Brown',
                itemId:'',
                objectId:''
            },
            {
                optionCode: 2,
                optionLabel: 'Jim Green',
            },
            {
                optionCode: 3,
                optionLabel: 'Joe Black',
            },
        ],
        editingKey:'',
        newData:{
            defaultOption:false,
            optionLabel:'',
            optionCode:0,
            itemId:'',
            objectId:''
        },

    };

    isEditing = record => record.optionCode === this.state.editingKey;

    edit(key) {
        this.setState({ editingKey: key });
    }

    columns=[
        {
            title: 'optionLabel',
            dataIndex: 'optionLabel',
            key: 'optionLabel',
            editable: true,
            render:(text,record,index)=>{
                const editable = this.isEditing(record);
                return editable ?(
                    <Input type={'text'} size={'small'} value={text} onChange={(e)=>{this.changeTableInput(text,record,index,e)}}/>
                ):(
                    <span>{text}</span>
                )
            }
        },
        {
            title: 'defaultOption',
            dataIndex: 'defaultOption',
            key: 'defaultOption',
            editable: true,
            render:(text,record,index)=>{
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                  <Checkbox checked={text} onChange={(e)=>{this.changeTableDefault(text,record,index,e)}}>默认</Checkbox>
            </span>
                ) : (
                    <span>{text?'默认':'-'}</span>
                );
            }
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (text, record,index) => {
                const editable = this.isEditing(record);
                return editable ? (
                    <span>

                    <CheckOutlined onClick={() => this.saveData(record)} className={'text-blue m-r-10'} />
                    <DeleteOutlined onClick={() => this.deleteTableData(index)} className={'text-blue'} />
            </span>
                ) : (
                    <span>
                        <EditOutlined
                            onClick={() => this.edit( record.optionCode)}
                            className={'text-blue m-r-10'} />
                        <DeleteOutlined onClick={() => this.deleteTableData(index)} className={'text-blue'} />
                    </span>
                );
            }
        }
    ];

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    updateData = (newData)=>{
        this.props.updateParantState(newData)
    };

    moveRow = (dragIndex, hoverIndex) => {
        const { data } = this.props;
        const dragRow = data[dragIndex];
        let post=update(this.props, {
            data: {
                $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
            },
        });
        this.updateData(post.data);
    };

    changeDefault=(e)=>{
        this.setState({
            newData:{
                ...this.state.newData,
                defaultOption:e.target.checked
            }
        })
    };

    changeLabel=(e)=>{
        this.setState({
            newData:{
                ...this.state.newData,
                optionLabel:e.target.value
            }
        })
    };

    addOptionClick=()=>{
        let {data,type,objectId}=this.props;
        let newData=this.state.newData;
        if(!newData.optionLabel){
            return;
        }
        let codeArr=data.map((item)=>{
            return item.optionCode
        })||[];
        let max=codeArr.length>0?codeArr.reduce(function(a , b){
            return b > a ? b : a;
        }):0;
        newData.optionCode=max!=0?max+1:1;
        newData.objectId=objectId;
        data.push(newData);
        if(newData.defaultOption&&type=='single'){
            data=data.map((item,i)=>{
                if(newData.optionCode!=item.optionCode){
                    item.defaultOption=false;
                };
                return item;
            });
        }
        this.updateData(data);

        this.setState({
            newData:{
                defaultOption:false,
                optionLabel:'',
                optionCode:0
            }
        })
    };

    changeTableInput=(text,record,index,e)=>{
        let data=this.props.data;
        data[index].optionLabel=e.target.value;
        this.updateData(data)
    };

    changeTableDefault=(text,record,index,e)=>{
        let {data,type}=this.props;
        // if(type=='single'){
        //     data=data.map((item,i)=>{
        //         item.defaultOption=false;
        //         return item;
        //     });
        // }
        data[index].defaultOption=e.target.checked;
        this.updateData(data)
    };

    saveData=(record)=>{
        let {data,type}=this.props;
        if(!record.optionLabel){
            return;
        }
        if(record.defaultOption&&type=='single'){
            data=data.map((item,i)=>{
                if(record.optionCode!=item.optionCode){
                    item.defaultOption=false;
                }
                return item;
            });
        }
        this.setState({
            editingKey:''
        });
        this.updateData(data)
    };

    deleteTableData=(index)=>{
        let data=this.props.data;
        // data=data.map((item,i)=>{
        //     if(index<i){
        //         item.optionCode=i-1
        //     };
        //     return item
        // });
        data.splice(index,1);
        this.updateData(data);
    };


    render() {
        return (
            <div style={{width: '75%', marginLeft: '21%'}}>
                {this.props.data.length>0?<DndProvider backend={HTML5Backend}>
                    <Table
                        showHeader={false}
                        size={'small'}
                        rowKey={'optionCode'}
                        columns={this.columns}
                        dataSource={this.props.data}
                        components={this.components}
                        pagination={false}
                        onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                        })}
                    />
                </DndProvider>:null}
                <div className={'flex m-t-20'}>
                    <Input type={'text'} size={'small'} value={this.state.newData.optionLabel} onChange={this.changeLabel} className={'m-r-10'} style={{width:'50%'}}/>
                    <Checkbox checked={this.state.newData.defaultOption} onChange={this.changeDefault}>默认</Checkbox>
                    <Button type={'primary'} size={'small'} onClick={this.addOptionClick}>添加</Button>
                </div>
            </div>

        );
    }
}