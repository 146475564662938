import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu, Layout } from 'antd';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import Routes from './routeConfig';
import defaultlogo from '../../imgs/logo_1_.png';
import {inject, observer} from "mobx-react";
import {message} from "antd/lib/index";
import Success from "../common/Success";
const {Content} = Layout;

@inject("loginStore")
@observer
@withRouter
class GzyModules extends React.Component{
    state = {
        collapsed: false,
        logo:defaultlogo,
        leftVal:200,
        gzyRoutes:Routes.gzyRoutes
    };

    operate= (e)=>{
        this[e.key]();
    };

    logout= ()=>{
        const {loginStore, history} = this.props,
            {doLogout} = loginStore;
        doLogout().then((resp) => {
            if (resp.code === 0) {
                history.push('/login');
            } else {
                message.error(resp.msg);
            }
        }, (resp) => {
            message.error(resp.msg);
        });
    }

    setSkin= ()=>{
        window.less.modifyVars(
            {
                '@primary-color': 'red',
                '@menu-dark-item-active-bg':'red',
                '@link-color': 'red',
                '@text-color':'red',
                '@btn-primary-bg': 'red',
                '@layout-header-background':'linear-gradient(137deg,rgba(76,56,148,1) 0%,rgba(32,30,106,1) 100%)',
                '@menu-dark-submenu-bg':'transparent',
            }
        )
            .then(() => {
                message.success('主题切换成功')
            })
            .catch(error => {
                message.error(`主题切换失败`);
                console.log(error)
            });
    }

    systemSetting= ()=>{
        this.props.history.push('/admins')
    }

    settingConfigs= ()=>{
        return (
            <Menu className={'fz-14'} onClick={this.operate} theme={'dark'}>
                <Menu.Item key={'logout'}>
                    <LegacyIcon type={'logout'} />
                    退出登录
                </Menu.Item>
                <Menu.Item key={'systemSetting'}>
                    <LegacyIcon type={'logout'} />
                    系统设置
                </Menu.Item>
                {/*<Menu.Item key={'setSkin'}>*/}
                    {/*<Icon type={'logout'} />*/}
                    {/*皮肤设置*/}
                {/*</Menu.Item>*/}
            </Menu>
        );
    }

    onCollapse = () => {
        let newcolaps=!this.state.collapsed;
        this.setState({
            collapsed: newcolaps,
            leftVal:newcolaps?80:200,
            logo:newcolaps?'http://rfgzyprod.oss-cn-beijing.aliyuncs.com/user-dir-prefix/logo/mini_web_logo/logomini_demo.png':defaultlogo
        });

    };
    //根据路由配置文件初始化路由
    getChildRoutes = (children,pid) => {
        return (children).map((cl,c)=>{
            let Component=cl.component;
            return (
                <Route
                    key={cl.path}
                    exact={true}
                    path={cl.path}
                    render={ ()=> {
                        return <Component {...cl} {...this.props} root={'/modules'}/>
                    }}
                />
            )
        })
    };
    componentWillMount(){

    }
    render=()=>{
        let { gzyRoutes }=this.state;
        let {logo,collapsed} =this.state;
        return (
            <Layout className={'global-layout'}>
                <Content className={'right-layout'} >
                    <Switch>
                        {
                            gzyRoutes.map((il,i)=>{
                                let Component=il.component;
                                if(il.children.length === 0){
                                    return (
                                        <Route
                                            key={il.path}
                                            exact={true}
                                            path={il.path}
                                            render={ ()=> {
                                                return <Component {...il} root={'/modules'}/>
                                            }}
                                        />
                                    )
                                }else{
                                    return this.getChildRoutes(il.children,il.id)
                                }
                            })
                        }
                        <Route path="/modules/success" render={()=>{
                            return <Success {...this.props} root={'/modules'} />
                        }}/>
                        <Redirect to="/modules/adminindex" />
                    </Switch>
                </Content>
            </Layout>
        )
    }
}
export default GzyModules