import React,{ Component } from 'react';
import {Layout,Tabs,message} from "antd";
import {observer, inject} from 'mobx-react';
import HeaderContent from '../../../../modules/layouts/headerContent';
import FileList from './fileList';

const { TabPane } = Tabs;
const {Content} = Layout;

@inject('signFilesStore')
@observer
class SignFiles extends Component{

    store=this.props.signFilesStore
    changeTab=(key)=>{
        this.store.setActiveTab(key);
    }

    parentSearchHandle=(type,val)=>{
        this.store[type]=val;
    }



    render(){
        const {root,history} =this.props;
        let {objectList} =this.store;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} text={this.props.text}/>
                <Content className="right-content pd-10 bg-white"  style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <Tabs onChange={this.changeTab} type="card">
                        {objectList.map((il,i)=>{
                            return (
                                <TabPane tab={il.objectName} key={il.objectId}>
                                    <FileList store={this.store} root={root} history={history} parentSearchHandle={this.parentSearchHandle}/>
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </Content>
            </Layout>
        )
    }

    componentWillMount(){
        console.log('params======',this.props.location.search);
        const {root} =this.props;
        let search=this.props.location.search;
        if(!search){
            this.store.getFileObjects();
            return;
        }

        const paramsString = search.substring(1);
        const searchParams = new URLSearchParams(paramsString);
        if(searchParams.get('success')){
            message.success('合同签署成功！');
            let signId=searchParams.get('signId')
            this.store.getFileObjects(signId);
            return;
        }
        if(root==="/modules"){
            const token = searchParams.get('token');
            const tenantId = searchParams.get('tenantId');
            window.sessionStorage.setItem("token",token);
            window.sessionStorage.setItem("tenantId",tenantId);
        }

        this.store.getFileObjects();
    }
    componentWillUnmount(){
        this.store.setInitState();
    }
}

export default SignFiles
