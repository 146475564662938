const items=[
    {text:'文本类型',value:1,type:'Text',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form01.png'},
    {text:'日期类型',value:7,type:'Date',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form02.png'},
    {text:'文本域类型',value:2,type:'Textarea',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form05.png'},
    {text:'单选类型',value:3,type:'SingleSelect',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form03.png'},
    {text:'多选类型',value:4,type:'MultiSelect',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form04.png'},
    {text:'整数类型',value:5,type:'Integer',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form06.png'},
    {text:'实数类型',value:6,type:'Real',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form07.png'},
    {text:'布尔类型',value:8,type:'Bool',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form31.png'},
    {text:'自动编号',value:9,type:'AutoNum',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form09.png'},
    {text:'关联关系类型',value:10,type:'Related',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form10.png'},
    {text:'主子明细类型',value:10,type:'Detail',imgSrc:'https://crm.xiaoshouyi.com/admin/img/form10.png'}
];

const SIGN_STATE=[
    // {text:'未发起',value:0,color:'#42BDF2'},
    {text:'待他签',value:1,color:'#4DBA9F'},
    {text:'已撤回',value:2,color:'#2E45C6'},
    {text:'已过期',value:3,color:'#F7B54A'},
    {text:'已拒签',value:4,color:'#EE7C7D'},
    {text:'待我签',value:5,color:'#4DBA9F'},
    {text:'签署完',value:6,color:'#2E84C6'}
    // {text:'已归档',value:7,color:'#ccc'}
];

const Utils={
    BuildUrl: (url, data)=> {
        if(typeof(url) === 'undefined' || url == null || url === '') {
            return '';
        }
        if(typeof(data) === 'undefined' || data == null || typeof(data) !== 'object') {
            return '';
        }
        url += (url.indexOf("?") !== -1) ? "" : "?";
        for(let k in data) {
            url += ((url.indexOf("=") !== -1) ? "&" : "") + k + "=" + encodeURI(data[k]);
            console.log(url);
        }
        return url;
    },
    getItemText:(type,isDetailed)=>{
        let obj=items.filter((item)=>type===item.value);
        if(type==10&&isDetailed===true){
            return obj&&obj.length>0?obj[1].text:''
        };
        return obj&&obj.length>0?obj[0].text:''
    },
    items,
    SIGN_STATE,
    getSignText:(val)=>{
        if(val<0){
            return {text:'状态错误',value:8}
        }
        let arr=SIGN_STATE.filter((il,i)=>{
            return il.value===val
        });
        return arr.length>0?arr[0]:{text:'状态错误',value:8}
    },
    formatCurrency : function(value) {
        value=Number(value).toFixed(2);
        //value=String(value);
        if (isNaN(value)) {
            value = "0.00";
        }
        var result = "";
        var idx=value.indexOf('-');
        if(idx!==-1){
            value=value.substring(idx+1);
        };
        var valueParts = value.split(".");
        var mostSignificationDigit = valueParts[0].length -1;   // 最高有效数字位，默认为个位
        var intervalOfDigit = 0; 	// 逗号之间的位数
        var digit, countOfSignificationDigit;
        for (var i = valueParts[0].length -1; i >= 0; i--) {
            digit = valueParts[0][i];
            result = digit + result;
            if (digit != "0") {
                mostSignificationDigit = i;
            }
            if (3 == ++intervalOfDigit) {
                result = "," + result;
                intervalOfDigit = 0;
            }
        }
        if (mostSignificationDigit == -1) {
            result = "0";
        }
        else {
            countOfSignificationDigit = valueParts[0].length - mostSignificationDigit;
            if (countOfSignificationDigit > 3) {
                result = result.substring(result.length - (countOfSignificationDigit%3 == 0 ? countOfSignificationDigit/3 - 1 : countOfSignificationDigit/3)  - countOfSignificationDigit);
            }
            else {
                result = result.substring(result.length - countOfSignificationDigit);
            }
        }
        if (valueParts.length == 2) {
            result += ".";
            var temp = 2 - valueParts[1].length;	// 是否需要补0
            for (var i = 0; i < temp; i++) {
                valueParts[1] += "0"
            }
            result += valueParts[1].substring(0, 2);
        }
        else {
            result += ".00";
        }
        if(idx !== -1){
            return '-'+result;
        }
        return result;
    },
    getDecimalNum :function(val,num=2){
        let number=Math.pow(10,num);
        return Number((val * number).toFixed(num)/ number)
    }

};

export { Utils }
