import {observable, action} from 'mobx';
import getJSON from '../../api/url.js';
import moment from "moment";

class StampListStore{
    @observable stampList;
    @observable loading;
    @observable current;
    @observable total;
    @observable size;

    @observable signatureName;
    @observable signTimeBgn;
    @observable signTimeEnd;



    constructor(){
        this.setInitState();
    }
    @action setInitState=(isreset)=>{
        this.current=1;
        this.total=0;
        this.size=10;
        this.stampList=[];
        this.loading=false;
        this.signatureName='';
        this.signTimeBgn=null;
        this.signTimeEnd=null;
    }
    @action searchHandle=(pageObject)=>{
        this.current=pageObject?pageObject.current:1;
        this.size=pageObject?pageObject.pageSize:20;
        let param=this.getSearchParam();
        this.getStempList(param);

    };

    @action getSearchParam=()=>{
        return {
            current:this.current,
            size:this.size,
        }
    };



    @action clearSearchList=()=>{
        this.setInitState(true);
        this.getSignatureUsedRecord(this.getSearchParam());
    };

    @action getStempList=(param)=>{
        this.loading=true;
        return getJSON({
            urlKey:'signatureList',
            method:'get',
            encodeData:param||{
                current:this.current,
                size:this.size,
            }
        }).then((res)=>{
            this.stampList=res.data.records;
            this.total=res.data.total;
            this.size=res.data.size;
            this.current=res.data.current;
            this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    };


    @action deleteStamp=(id)=>{
        return getJSON({
            urlKey:'deleteSignature',
            method:'delete',
            encodeData:{
                id
            }
        })
    }

    @action setDefaultSignature=(id)=> {
        return getJSON({
            urlKey: 'setDefaultSignature',
            method: 'post',
            encodeData: {
                id
            },
            data:{}
        })
        // return axios.post('/esign/v1/signature/setDefault?id='+id,{},{headers:{
        //         'X-Rental-Token': window.sessionStorage.getItem('token') || '',
        //         'tenantId':window.sessionStorage.getItem('tenantId'),
        //         'Content-Type': 'application/json; charset=utf-8',
        //     }})
    }

    @action setDate=(type,field,value)=>{
        let date=`${type}${field}`;
        this[date]=value;
    };

    @action searchHandleDetail=(pageObject)=>{
        this.current=pageObject?pageObject.current:1;
        this.size=pageObject?pageObject.pageSize:20;
        let param=this.getSearchParamDetail();
        this.getSignatureUsedRecord(param);

    };

    @action getSearchParamDetail=()=>{
        let {signatureName,
        signTimeBgn,
        signTimeEnd}=this;
        return {
            current:this.current,
            size:this.size,
            signatureName,
            signTimeBgn:signTimeBgn?moment(signTimeBgn.valueOf()).valueOf():'',
            signTimeEnd:signTimeEnd?moment(signTimeEnd.valueOf()).valueOf():'',

        }
    };

    @action getSignatureUsedRecord=(param)=>{
        this.loading=true;
        getJSON({
            urlKey:"signatureUsedRecord",
            method:'get',
            encodeData:param||{
                current:this.current,
                size:this.size,
            }
        }).then((res)=>{
            this.stampList=res.data.records;
            this.total=res.data.total;
            this.size=res.data.size;
            this.current=res.data.current;
            this.loading=false;
            return res
        },(res)=>{
            return res;
        })
    }


}

const stampListStore = new StampListStore();
export default stampListStore;
export {stampListStore}