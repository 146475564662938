import React ,{Component} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Layout, message} from 'antd';
import HeaderContent from "../../../layouts/headerContent";

const {Content} = Layout;
export default class SignFilesWrap extends Component{
    state={
        fadada:''
    }

    componentWillMount(){
        const {location,history} =this.props;
        if(!location.state){
            history.goBack();
            message.warn('没有返回正确的地址！');
            return;
        };
        this.fadada=location.state['fadada'];

    }

    render(){
        const {root} =this.props;
        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                    <HeaderContent history={this.props.history} title={this.props.text}>
                        <Button type={'link'} size={'small'} icon={<LegacyIcon type={'arrow-left'} />} style={{float:'right',marginTop:10,marginRight:30}} onClick={()=>{
                            this.props.history.goBack()
                        }}>返回</Button>
                    </HeaderContent>
                <Content className="right-content pd-10 bg-white" style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <iframe src={this.fadada} style={{width:'100%',height:'100vh',border:'none'}}></iframe>
                </Content>
            </Layout>
        );

    }
}