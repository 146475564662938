import React from 'react';

class AdminIndex extends React.Component{


    render(){
        return (
            <div>我是admin首页</div>
        )
    }
}

export default AdminIndex