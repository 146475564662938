import React from 'react';
import { observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

@observer
class AuthorizedRoute extends React.Component {
    parseToken(){
        let token = '';
        try{
            if(!window.name){
                return token;
            }
            const session = JSON.parse(window.name);
            token = session&&session.token;
            Object.keys(session).map((k) => {
                window.sessionStorage.setItem(k, session[k]);
                return k;
            });
            console.log(window.sessionStorage)
        }catch(e){
            token = '';
        }
        return token;
    }
    render() {
        const { component: Component} = this.props;
        let Token = window.sessionStorage.getItem('token');
        // if(!Token){
        //     Token = this.parseToken();
        //     console.log('token--------------------------',Token);
        // }
        return (
            <div className="authorized-router-warpper">
                <Route
                    exact={true}
                    render={() => {
                        // return Token
                        //     ? <Component {...this.props} />
                        //     : <Redirect to="/login" />;
                        return <Component {...this.props} />
                    }}
                />
            </div>
        );
    }
}

export default AuthorizedRoute;
