import {observable, action} from 'mobx';
import getJSON from '../api/url.js';

class LoginStore {
    @observable account;
    @observable password;

    constructor() {
        this.account = null;
        this.password = null;
    }

    @action doLogin= (data)=>{
        return getJSON({
            urlKey:'login',
            method:'post',
            data:data||{
                account: data.account,
                password: data.password
            },
        }).then((resp)=>{
            return resp
        })
    };

    @action doLogout= ()=>{
        return getJSON({
            urlKey:'logout',
            method:'post',
            // isurlData:true,
            data:{

            }
        }).then((resp)=>{
            return resp
        })
    };

    @action getObject= (url)=>{
        return getJSON({
            urlKey:url,
            method:'get',
            encodeData:{
                tenantId: window.sessionStorage.getItem('tenantId'),
                customFlg: 0
            },
        }).then((resp)=>{
            return resp
        })
    }
}

const loginStore = new LoginStore();//通过new 创建一个homeStore对象实例通过export导出
export default loginStore;
export {loginStore}