import React,{ Component } from 'react';
import {Layout, Menu, Tabs,Button} from "antd";
import {Link } from 'react-router-dom';

import FieldContent from '../../../components/fieldContent/FieldContent';
import SettingLayout from '../../../components/SettingLayout';
import HeaderContent from '../../../modules/layouts/headerContent';
import LoadingContent from './loadingContent';
import {inject, observer} from "mobx-react";
import './objectMenuIndex.less'
const {Content,Sider} = Layout;
const { TabPane } = Tabs;

@inject('menuWrapStore')
@observer
class Index extends Component{
    state={
        activeTabKey:'field',
    };
    store=this.props.menuWrapStore;

    tabsData=()=> {
        const {selectObj}=this.props.menuWrapStore;
        let data=[
            {
                key: 'field',
                tabTitle: '字段设置',
                tabContent: () => <FieldContent {...selectObj} />
            },
        ];
        return data
    };
    componentWillMount(){
        this.store.getObject();
    }

    render(){
        const {loading,objectList,clickMenuHandle,selectObj}=this.props.menuWrapStore;
        const tabsData=this.tabsData();
        return (
            <Layout className="right-content-admin-layout" >
                <HeaderContent history={this.props.history} text={this.props.text}/>
                {!loading?<Layout className={'object-inner-layout'}>
                    <h1 className={'object-list-title'}>实体列表{objectList.length}个</h1>
                    <Sider theme={'light'} className={'object-sider'}>
                        <Menu
                            theme="light"
                            mode="inline"
                            selectedKeys={[(selectObj?String(selectObj.objectId):'')]}
                            onClick={clickMenuHandle}
                        >
                            {
                                objectList.map((il,i)=>{
                                    return (
                                        <Menu.Item key={il.objectId} text={il.name}>
                                            <span className="nav-text">{il.name}</span>
                                        </Menu.Item>
                                    )
                                })
                            }
                        </Menu>
                        {/*<Button type={'primary'} size={'large'} className={'object-new-btn'}>新建</Button>*/}
                    </Sider>
                    <Content className="right-content">
                        {selectObj?<SettingLayout
                            apiSymbol={selectObj.apiSymbol}
                            // standTitle={`设置-${selectObj.objectName}`}
                            tabsData={this.tabsData()}
                            defaultActiveTab={this.state.activeTabKey}/>:null}

                    </Content>
                </Layout>:<LoadingContent loading/>}

            </Layout>

        )
    }

    componentWillUnmount(){
        this.store.setInitState();
    }
}
export default  Index
