import React,{ Component } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Layout, Table, Tabs, Tag} from "antd";
import {observer, inject} from 'mobx-react';
import moment from 'moment';
import HeaderContent from '../../../../modules/layouts/headerContent';
import {Utils} from "../../../utils";
const {Content} = Layout;
const { TabPane } = Tabs;

@inject('accountBillStore')
@observer
class AccountBillDetail extends Component{
    store=this.props.accountBillStore;
    getCostColumn=()=>{
        let arr= [

            {
                title:'产品名称',
                dataIndex:'pckProdName',

            },


            {
                title:'消费金额（元）',
                dataIndex:'pckProdPrice',

                render:(totalAmount)=>{
                    return Utils.getDecimalNum(totalAmount?totalAmount/100:0)
                }
            },
            {
                title:'备注',
                dataIndex:'content',

            },
        ]
        return arr;
    }

    render(){

        const {root} =this.props;
        let {detailData,dataList,loading,current,size,total,searchHandle} =this.store;

        return (
            <Layout className={root!=="/modules"?"right-content-admin-layout":"right-content-modules-layout"}>
                <HeaderContent history={this.props.history} title={this.props.text}>
                    <Button size={'small'} icon={<LegacyIcon type={'arrow-left'} />} style={{float:'right',top:10,right:10}} onClick={()=>this.props.history.goBack()}>返回</Button>
                </HeaderContent>
                <Content className="right-content bill-detail-wrap"  style={root!=="/modules"?{}:{marginLeft:15,marginRight:15}}>
                    <div className={'bill-detail-info pd-10 bg-white m-b-10'}>
                        <h3 className={'border-tit'}>基本信息</h3>
                        <div className={'flex space-between p-t-10 m-b-10'}>
                            <div className={'flex-item flex'}>
                                <div className={'flex-item text-right m-r-10'}>
                                    <span className={'text-gray'}>日期：</span>
                                </div>
                                <div className={'flex-item'}>
                                    <span className={'text-black'}>{moment(detailData.statisticTime).format('YYYY-MM-DD HH:mm:ss')}</span>

                                </div>
                            </div>
                            <div className={'flex-item flex'}>
                                <div className={'flex-item text-right m-r-10'}>
                                    <span className={'text-gray'}>使用套餐：</span>
                                </div>
                                <div className={'flex-item'}>
                                    <span className={'text-black'}>{detailData.pckName}</span>

                                </div>
                            </div>
                            <div className={'flex-item flex'}>
                                <div className={'flex-item text-right m-r-10'}>
                                    <span className={'text-gray'}>消费金额：</span>
                                </div>
                                <div className={'flex-item'}>
                                    <span className={'text-black'}>{Utils.formatCurrency(Utils.getDecimalNum(detailData.totalAmount/100))}元</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'pd-10 bg-white m-b-10'}>
                        <h3 className={'border-tit'}>消费明细</h3>
                        <Table className={'m-b-10 m-t-10'} loading={loading}
                               columns={this.getCostColumn()} rowKey={'id'} dataSource={dataList} size={'small'}
                               total={total}
                               onChange={(pagination, filters, sorter)=>{
                                   searchHandle(pagination);
                               }}
                               pagination={{
                                   showSizeChanger:true,
                                   showQuickJumper:true,
                                   defaultCurrent:1,
                                   current:current,
                                   pageSize:size,
                                   total:total,
                               }} />
                    </div>



                </Content>
            </Layout>
        );
    }
    componentDidMount() {
        let {location} =this.props;

        this.store.setDetailid(location.state.id)
    }
    componentWillUnmount() {
        this.store.setInitState();
    }
}

export default AccountBillDetail